<template>
  <div>
    <div v-if="fieldDisabled">{{ getAddressText(appointmentAddress) }}</div>
    <appointment-address-field v-else-if="fieldToggle" :id="id" :appointment-address="appointmentAddress" />
    <div v-else title="Edit Appointment Address" class="partner-appointment-field cursor-text editable" @click="toggleAppointmentAddress()">{{ getAddressText(appointmentAddress) }}</div>
  </div>
</template>

<script>
import EventBus from '../../js/eventBus';
import appointmentAddressField from './appointmentAddressField';

import { useAppStore } from '../../store/app';
import { useDomainStore } from '../../store/domain';

export default {
  props: {
    id: String,
    appointmentAddress: String,
    fieldToggle: Boolean,
    fieldDisabled: Boolean,
  },

  data () {
    return {
      appStore: useAppStore(),
      domainStore: useDomainStore(),
    }
  },

  components: {
    'appointment-address-field': appointmentAddressField,
  },

  computed: {
    addressesList () {
      return this.domainStore.addressesList();
    },
    addressList () {
      const list = this.addressesList[this.currentProduct];
      return list;
    },

    currentProduct () {
      return this.appStore.currentProduct;
    },
  },

  methods: {
    getAddressText (addressKey) {
      if (!addressKey) return this.addressList[0].text;
      const addressText = this.addressList.find(add => add.value === addressKey);
      if (addressText && addressText.value) return addressText.text;
      return addressKey;
    },

    toggleAppointmentAddress () {
      EventBus.$emit('toggle-appointment-address', { id: this.id, show: true });
    }
  }
}
</script>
