<template>
  <div id="tableWrapper" class="table-wrapper">
    <table v-if="apps && apps.length > 0" class="app-table">
      <thead>
        <tr>
          <!-- HKID/NRIC label -->
          <th class="table-header right-border" :data-content="tableHeaderLabel(UserViewMixin_appIdentifier)" />
          <!-- Every other applications' personalNumber -->
          <th
            v-for="(app, index) in apps"
            :key="app._id"
            class="table-header rounded-border"
            @mouseover="hoverCol(`${app._id}-col`, 'mouseover')"
            @mouseleave="hoverCol(`${app._id}-col`, 'mouseleave')"
          >
            <div class="personal-number-flexbox">
              <span :data-content="`${ startIndex + index + 1 }. ${ app[UserViewMixin_appIdentifier] }`" class="cursor-pointer" @dblclick="copyFieldMixin_copy(`${ app[UserViewMixin_appIdentifier] }`)" />
              <!-- Revised offer info thing -->
              <span v-if="app.offerRevised || app.offerNotChanged">
                <revise-offer-info :type="(app.offerRevised) ? 'revised' : 'no-change'" />
              </span>
            </div>
          </th>
        </tr>
      </thead>

      <tbody>
        <template v-for="(field, index) in fields">
          <tr
            v-if="field.display !== false"
            :id="index"
            :key="index"
          >
            <!-- Field Name -->
            <th
              class="table-header"
              :data-content="tableHeaderLabel(index)"
              @mouseover="hoverRow(`#${index} > td`, 'mouseover')"
              @mouseleave="hoverRow(`#${index} > td`, 'mouseleave')"
            />
            <!-- Application Data -->
            <td
              v-for="(app) in apps"
              :key="app._id"
              class="table-content"
              :class="`${app._id}-col`"
              @mouseover="hoverCol(`${app._id}-col`, 'mouseover')"
              @mouseleave="hoverCol(`${app._id}-col`, 'mouseleave')"
            >
              <partner-comment v-if="index === 'partnerComment'" :id="app._id" :comment="app.partnerComment" :field-toggle="partnerCommentStatus[app._id]" />
              <upload-documents
                v-else-if="index === 'uploadDocuments'"
                :id="app._id"
                :field-name="index"
                :selected-documents="app[index]"
                :field-disabled="true"
                :field-toggle="uploadDocumentsStatus[app._id]"
              />
              <field-display v-else-if="index === 'address'" :id="app._id" :field-name="index" :field-val="app[index]" :additional-data="{ residentialStatus: app.residentialStatus }" />
              <field-display v-else-if="index === 'birthYear'" :id="app._id" :field-name="index" :field-val="app[index]" :additional-data="{ birthMonth: app.birthMonth }" />
              <field-display v-else-if="index === 'myInfoData'" :id="app._id" :field-name="index" :field-val="app.dataSource" :additional-data="{ showHasAdditionalMyinfoData: app.showHasAdditionalMyinfoData }" />
              <field-display v-else :id="app._id" :field-name="index" :field-val="app[index]" @showUploadedFiles="showUploadedFiles" />
            </td>
          </tr>
        </template>
      </tbody>

      <tfoot>
        <!-- CTA Panel -->
        <tr>
          <td class="table-header" :data-content="tableHeaderLabel('givenOffer')" />
          <td v-for="(app, index) in apps" :key="app._id" class="cta-panel given-offer">
            <div>
              <div v-for="(field, fieldId) in list" :key="fieldId" class="given-offer-panel">
                <div :data-content="`${fieldLabel(field)}:`" />
                <div :data-content="fieldData(field, apps[index].offer[field])" />
              </div>
              <div class="given-offer-panel">
                <div :data-content="`${fieldLabel('bankApprovedBy')}:`" />
                <div :data-content="fieldData('bankApprovedBy', apps[index].offer.bankApprovedBy)" />
              </div>
              <div class="given-offer-panel">
                <div :data-content="`${fieldLabel('bankApprovedDate')}:`" />
                <div :data-content="formatDateTime(apps[index].offer.bankApprovedDate)" />
              </div>
            </div>
          </td>
        </tr>
      </tfoot>
    </table>

    <div v-else class="cleared-section-wrapper">
      <cleared-table />
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import tableMixin from '../../mixins/cssMixin/tableMixin'
import displayOfferMixin from '../../mixins/displayOfferMixin'
import fieldDisplay from '../fieldDisplay';
import reviseOfferInfoVue from '../reviseOfferInfo.vue';
import copyFieldMixin from '../../mixins/copyFieldMixin';

export default {
  components: {
    'field-display': fieldDisplay,
    'revise-offer-info': reviseOfferInfoVue,
  },
  mixins: [tableMixin, displayOfferMixin, copyFieldMixin],
  props: {
    apps: Array,
    fields: Object,
    startIndex: Number,
  },
  data () {
    return {
      offerFunnel: 'pre-disburse',
    }
  },
  methods: {
    formatDateTime (dateTime) {
      return (this.language === 'EN') ? moment(dateTime).format('MMM D, YYYY h:mmA') : moment(dateTime).format('YYYY年M月D日 h:mmA');
    },
    showUploadedFiles (applicationId) {
      const routeData = this.$router.resolve({ name: 'file-downloads', query: { id: applicationId } });
      window.open(routeData.href, '_blank');
    },
  },
}
</script>

<style scoped>
.table-wrapper tfoot th {
  padding: 3px 10px 0;
}

table > thead td:first-child::before {
  content: attr(data-content);
}

table > tbody td:first-child::before {
  content: attr(data-content);
}
table > tbody div::before {
  content: attr(data-content);
}
table > tbody span::before {
  content: attr(data-content);
}

table > tbody p::before {
  content: attr(data-content);
}

table > tfoot th:first-child::before {
  content: attr(data-content);
}
table > tfoot div::before {
  content: attr(data-content);
}
</style>
