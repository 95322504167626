<template>
  <div>
    <div v-if="fieldToggle">
      <partner-comment-field :id="id" :comment="comment" />
    </div>
    <div
      v-else
      class="partner-comment-field"
      :style="{'overflow-y': comment ? 'scroll' : 'unset' }"
      :class="(!comment) ? 'cursor-text' : 'cursor-text editable'"
      :title="(!comment) ? 'Add comment' : 'Edit comment'"
      @click="$parent.partnerCommentToggle({id, show: true})"
    >
      <span v-if="!comment" class="no-comment"> {{ $parent.getButtonLabel('addComment') }} </span>
      <div v-else-if="$parent.appStore.currentProduct === 'qvCreditPersonalLoan' && $parent.appStore.currentTab === 'acknowledged'">{{ comment }}</div>
      <div v-else :data-content="comment" />
    </div>
  </div>
</template>

<script>
import partnerCommentField from './partnerCommentField';

export default {
  props: {
    id: String,
    comment: String,
    fieldToggle: Boolean,
  },

  components: {
    'partner-comment-field': partnerCommentField
  }
}
</script>
