<template>
  <div id="tableWrapper" class="table-wrapper">
    <table v-if="apps && apps.length > 0" class="app-table">
      <thead>
        <tr>
          <!-- HKID/NRIC label -->
          <th class="table-header right-border" :data-content="tableHeaderLabel(UserViewMixin_appIdentifier)" />
          <th
            v-for="(app, index) in apps"
            :key="app._id"
            class="table-header rounded-border"
            @mouseover="hoverCol(`${app._id}-col`, 'mouseover')"
            @mouseleave="hoverCol(`${app._id}-col`, 'mouseleave')"
          >
            <div class="personal-number-flexbox">
              <span :data-content="`${ index + 1 }. ${ app[UserViewMixin_appIdentifier] }`" class="cursor-pointer" @dblclick="copyFieldMixin_copy(`${ app[UserViewMixin_appIdentifier] }`)" />
              <!-- Revise offer alert thing -->
              <span v-if="app.offerRevised || app.offerNotChanged">
                <revise-offer-info :type="(app.offerRevised) ? 'revised' : 'no-change'" />
              </span>
            </div>
          </th>
        </tr>
      </thead>

      <tbody v-for="(field, index) in fields" :key="index">
        <tr
          v-if="field.display !== false"
          :id="index"
        >
          <!-- Field Name  -->
          <th
            class="table-header"
            :data-content="tableHeaderLabel(index)"
            @mouseover="hoverRow(`#${index} > td`, 'mouseover')"
            @mouseleave="hoverRow(`#${index} > td`, 'mouseleave')"
          />
          <!-- Application Data -->
          <td
            v-for="(app) in apps"
            :key="app._id"
            class="table-content"
            :class="`${app._id}-col`"
            @mouseover="hoverCol(`${app._id}-col`, 'mouseover')"
            @mouseleave="hoverCol(`${app._id}-col`, 'mouseleave')"
          >
            <partner-comment v-if="index === 'partnerComment'" :id="app._id" :comment="app.partnerComment" :field-toggle="partnerCommentStatus[app._id]" />
            <appointment-booking v-else-if="index === 'appointmentDateTime'" :id="app._id" :date-time="app.appointmentDateTime" :field-toggle="appointmentBookingStatus[app._id]" :disable="true" />
            <appointment-documents v-else-if="index === 'appointmentDocuments'" :id="app._id" :field-name="index" :selected-documents="app[index]" :field-toggle="appointmentDocumentsStatus[app._id]" />
            <appointment-address v-else-if="index === 'appointmentAddress'" :id="app._id" :field-name="index" :appointment-address="app[index]" :field-disabled="true" />
            <field-display v-else-if="index === 'address'" :id="app._id" :field-name="index" :field-val="app[index]" :additional-data="{ personalAddress: app.personalAddress, address: app.address, residentialStatus: app.residentialStatus }" />
            <field-display v-else-if="index === 'birthYear'" :id="app._id" :field-name="index" :field-val="app[index]" :additional-data="{ birthMonth: app.birthMonth }" />
            <field-display v-else-if="index === 'myInfoData'" :id="app._id" :field-name="index" :field-val="app.dataSource" :additional-data="{ showHasAdditionalMyinfoData: app.showHasAdditionalMyinfoData }" />
            <field-display
              v-else
              :id="app._id"
              :field-name="index"
              :field-val="app[index]"
              @showUploadedFiles="showUploadedFiles"
            />
          </td>
        </tr>
      </tbody>

      <tfoot>
        <!-- CTA Panel -->
        <tr>
          <td class="table-header" :data-content="tableHeaderLabel('givenOffer')" />
          <td v-for="(app, index) in apps" :key="app._id" class="cta-panel">
            <div><chosen-panel :app="app" :offer="parseOfferData(apps[index])" :got-files="(app.uploads) ? true : false" /></div>
          </td>
        </tr>
      </tfoot>
    </table>

    <div v-else class="cleared-section-wrapper">
      <cleared-table />
    </div>

    <reject-app-modal stage="chosen" />
  </div>
</template>

<script>
import partnerPortalApi from '../../mixins/apiServices/partnerPortal';
import displayOfferMixin from '../../mixins/displayOfferMixin'
import tableMixin from '../../mixins/cssMixin/tableMixin';
import rejectAppModal from '../rejectAppModal';
import fieldDisplay from '../fieldDisplay';
import checkbox from '../inputFields/checkbox';
import chosenPanel from '../chosenPanel';
import reviseOfferInfoVue from '../reviseOfferInfo.vue';
import copyFieldMixin from '../../mixins/copyFieldMixin';

import { useAppStore } from '../../store/app';

export default {
  components: {
    'reject-app-modal': rejectAppModal,
    'field-display': fieldDisplay,
    'chosen-panel': chosenPanel,
    checkbox,
    'revise-offer-info': reviseOfferInfoVue,
  },

  mixins: [tableMixin, partnerPortalApi, displayOfferMixin, copyFieldMixin],

  props: {
    apps: Array,
    fields: Object,
  },

  data () {
    return {
      offerFunnel: 'pre-disburse',
      panelFields: {},
      appStore: useAppStore(),
    }
  },

  computed: {
    currentProduct () {
      return this.appStore.currentProduct;
    }
  },
  methods: {
    showUploadedFiles (applicationId) {
      const routeData = this.$router.resolve({ name: 'file-downloads', query: { id: applicationId } });
      window.open(routeData.href, '_blank');
    },
    parseOfferData (app) {
      const { offer, documentsReviewed } = app;

      return {
        ...offer,
        documentsReviewed,
      };
    },
  },
}
</script>

<style scoped>
table > thead td:first-child::before {
  content: attr(data-content);
}

table > tbody td:first-child::before {
  content: attr(data-content);
}
table > tbody div::before {
  content: attr(data-content);
}
table > tbody span::before {
  content: attr(data-content);
}
table > tbody p::before {
  content: attr(data-content);
}

table > tfoot th:first-child::before {
  content: attr(data-content);
}
</style>
