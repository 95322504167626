<template>
  <div title="Edit Required Documents">
    <upload-documents-field v-if="fieldToggle && !fieldDisabled" :id="id" :selected-documents="selectedDocuments" />
    <div v-else @click="toggleDocumentList()">
      <div v-if="selectedDocuments.length === 0">N/A</div>
      <ul v-else class="upload-doc-list" :class="!fieldDisabled ? 'clickable' : ''">
        <li v-for="doc in selectedDocuments" :key="doc" :data-content="getDocumentText(doc)" />
      </ul>
    </div>
  </div>
</template>

<script>
import EventBus from '../../js/eventBus';
import uploadDocumentsField from './uploadDocumentsField';

import { useDomainStore } from '../../store/domain';

export default {
  props: {
    id: String,
    selectedDocuments: Array,
    fieldToggle: Boolean,
    fieldDisabled: Boolean
  },

  data () {
    return {
      domainStore: useDomainStore(),
    }
  },

  computed: {
    uploadDocuments () {
      return this.domainStore.uploadDocuments();
    }
  },

  components: {
    'upload-documents-field': uploadDocumentsField
  },

  methods: {
    getDocumentText (docKey) {
      const docText = this.uploadDocuments.find(doc => doc.value === docKey);
      if (docText && docText.text) return docText.text;
      return docKey;
    },

    toggleDocumentList () {
      EventBus.$emit('toggle-upload-documents', { id: this.id, show: true });
    }
  }
}
</script>

<style scoped>
li::before {
  content: attr(data-content);
}
</style>
