<template>
  <div id="tableWrapper" class="table-wrapper">
    <table v-if="apps && apps.length > 0" class="app-table">
      <thead>
        <tr>
          <!-- HKID/NRIC label -->
          <th class="table-header right-border" :data-content="tableHeaderLabel(UserViewMixin_appIdentifier)" />
          <!-- Every other applications' personalNumber -->
          <th
            v-for="(app, index) in apps"
            :key="app._id"
            class="table-header rounded-border"
            @mouseover="hoverCol(`${app._id}-col`, 'mouseover')"
            @mouseleave="hoverCol(`${app._id}-col`, 'mouseleave')"
          >
            <div class="personal-number-flexbox">
              <span :data-content="`${ index + 1 }. ${ app[UserViewMixin_appIdentifier] }`" class="cursor-pointer" @dblclick="copyFieldMixin_copy(`${ app[UserViewMixin_appIdentifier] }`)" />
              <!-- Red alert rejection thing -->
              <span v-if="app.previouslyRejectedData">
                <previously-rejected-info :rejection-data="app.previouslyRejectedData" :personal-number="app.personalNumber" />
              </span>
            </div>
          </th>
        </tr>
      </thead>

      <tbody v-for="(field, index) in fields">
          <tr v-if="field.display !== false" :id="index" :key="index">
            <!-- Field Name -->
            <th
            class="table-header"
            :data-content="tableHeaderLabel(index)"
            @mouseover="hoverRow(`#${index} > td`, 'mouseover')"
            @mouseleave="hoverRow(`#${index} > td`, 'mouseleave')"
          />
          <!-- Application data -->
          <td
            v-for="(app) in apps"
            :key="app._id"
            class="table-content"
            :class="`${app._id}-col`"
            @mouseover="hoverCol(`${app._id}-col`, 'mouseover')"
            @mouseleave="hoverCol(`${app._id}-col`, 'mouseleave')"
          >
            <partner-comment v-if="index === 'partnerComment'" :id="app._id" :comment="app.partnerComment" :field-toggle="partnerCommentStatus[app._id]" />
            <upload-documents v-else-if="index === 'uploadDocuments'" :id="app._id" :field-name="index" :selected-documents="app[index]" :field-toggle="uploadDocumentsStatus[app._id]" />
            <field-display v-else-if="index === 'address'" :id="app._id" :field-name="index" :field-val="app[index]" :additional-data="{ residentialStatus: app.residentialStatus }" />
            <field-display v-else-if="index === 'birthYear'" :id="app._id" :field-name="index" :field-val="app[index]" :additional-data="{ birthMonth: app.birthMonth }" />
            <field-display v-else-if="index === 'myInfoData'" :id="app._id" :field-name="index" :field-val="app.dataSource" :additional-data="{ showHasAdditionalMyinfoData: app.showHasAdditionalMyinfoData }" />
            <field-display v-else :id="app._id" :field-name="index" :field-val="app[index]" @showUploadedFiles="showUploadedFiles" />
          </td>
        </tr>
      </tbody>

      <tfoot>
        <!-- CTA Panel -->
        <tr>
          <td class="table-header" :data-content="tableHeaderLabel('giveOffer')" />
          <td v-for="app in apps" :key="app._id" class="cta-panel">
            <div><lead-ipa-panel :app-id="app._id" :app="app" /></div>
          </td>
        </tr>
      </tfoot>
    </table>

    <div v-else class="cleared-section-wrapper">
      <cleared-table />
    </div>

    <give-offer-modal />
    <decline-app-modal />
  </div>
</template>

<script>
import leadIPAPanel from '../leadIPAPanel';
import tableMixin from '../../mixins/cssMixin/tableMixin';
import giveOfferModal from '../giveOfferModal';
import declineAppModal from '../declineAppModal';
import fieldDisplay from '../fieldDisplay';
import previouslyRejectedInfo from '../previouslyRejectedInfo';
import copyFieldMixin from '../../mixins/copyFieldMixin';

export default {
  components: {
    'lead-ipa-panel': leadIPAPanel,
    'give-offer-modal': giveOfferModal,
    'decline-app-modal': declineAppModal,
    'field-display': fieldDisplay,
    'previously-rejected-info': previouslyRejectedInfo,
  },
  mixins: [tableMixin, copyFieldMixin],
  props: {
    apps: Array,
    fields: Object,
  },
  methods: {
    showUploadedFiles (applicationId) {
      const routeData = this.$router.resolve({ name: 'file-downloads', query: { id: applicationId } });
      window.open(routeData.href, '_blank');
    },
  },
}
</script>
