<template>
  <div>
    <div :class="{ 'info-icon': 1, 'text-center': 1, 'revised-info-icon': type === 'revised', 'noChange-info-icon': type === 'no-change' }" @click="showDetails = !showDetails">
      <span class="text-white">{{ getLabel() }}</span>
    </div>
    <div v-if="showDetails" class="bubble revise-offer-div">
      <div class="inner-arrow" />
      <div style="text-align: left; font-weight: normal">{{ getDescription() }}</div>
      <img
        src="../assets/img/cross.svg"
        width="14"
        height="14"
        style="cursor:pointer"
        alt="close"
        @click="showDetails = false"
      >
    </div>
  </div>
</template>

<script>
import { useDomainStore } from '../store/domain';

export default {
  props: {
    type: String
  },

  data () {
    return {
      showDetails: false,
      domainStore: useDomainStore(),
    }
  },

  computed: {
    miscTexts () {
      return this.domainStore.miscTexts();
    },
  },

  methods: {
    getText (field) {
      if (this.miscTexts[field]) return this.miscTexts[field].label;
      return field;
    },

    getLabel () {
      const field = (this.type === 'revised') ? 'revisedOfferLabel' : 'noChangeOfferLabel';

      if (this.miscTexts[field]) return this.miscTexts[field].label;

      if (this.type === 'revised') return 'RO';
      return 'NC';
    },

    getDescription () {
      const field = (this.type === 'revised') ? 'revisedOfferDescription' : 'noChangeOfferDescription';

      if (this.miscTexts[field]) return this.miscTexts[field].label;

      if (this.type === 'revised') return 'Revised Offer (Offer has been revised by you)';
      return 'No Change (You made no changes to this offer after a revision request).';
    }
  },
}
</script>

<style scoped>
.revise-offer-div {
  display: flex;
  flex-direction: row;
  gap: 10px;
  position: relative;
}

.info-icon {
  padding: 3px;
  width: 22px;
  height: 22px;
  cursor: pointer;
  font-size: 10px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.revised-info-icon {
  background: red;
}

.noChange-info-icon {
  background: #4B0082;
}

.bubble {
  position: absolute;
  width: 90%;
  background: #ffffff;
  border-radius: 10px;
  padding: 15px;
  color: #000;
  top: 40px;
  right: -1px;
  box-shadow: 1px 0px 4px 0px #bdbdbd;
}

div.inner-arrow {
  background: #FFFFFF;
  position: absolute;
  width: 15px;
  height: 15px;
  transform: rotate(45deg);
  top: -8px;
  right: 5%;
  z-index: 0;
  border-left: 1px solid #e1dbdb;
  border-top: 1px solid #e1dbdb;
}

.bubble > .close-button {
  position: absolute;
  height: 15px;
  width: 15px;
  top: 15px;
  right: 20px;
  cursor: pointer;
  color: #afafaf;
}
</style>
