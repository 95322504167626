<template>
  <div id="tableWrapper" class="table-wrapper">
    <table v-if="apps && apps.length > 0" class="app-table">
      <thead>
        <tr>
          <!-- HKID/NRIC label -->
          <th class="table-header right-border" :data-content="tableHeaderLabel(UserViewMixin_appIdentifier)" />
          <!-- Every other applications' personalNumber -->
          <th
            v-for="(app, index) in apps"
            :key="app._id"
            class="table-header rounded-border"
            @mouseover="hoverCol(`${app._id}-col`, 'mouseover')"
            @mouseleave="hoverCol(`${app._id}-col`, 'mouseleave')"
          >
            <div class="personal-number-flexbox">
              <!-- Only allow highlighting for QVCreditPersonalLoan -->
              <span v-if="currentProduct === 'qvCreditPersonalLoan'">{{ index + 1 }}. {{ app[UserViewMixin_appIdentifier] }}</span>
              <span v-else :data-content="`${ index + 1 }. ${ app[UserViewMixin_appIdentifier] }`" class="cursor-pointer" @dblclick="copyFieldMixin_copy(`${ app[UserViewMixin_appIdentifier] }`)" />

              <!-- Red alert rejection thing -->
              <span v-if="app.offerRevised || app.offerNotChanged">
                <revise-offer-info :type="(app.offerRevised) ? 'revised' : 'no-change'" />
              </span>
            </div>
          </th>
        </tr>
      </thead>

      <tbody>
        <template v-for="(field, index) in fields">
          <tr v-if="field.display !== false" :id="index" :key="index">
            <!-- Field Name -->
            <th
              class="table-header"
              @mouseover="hoverRow(`#${index} > td`, 'mouseover')"
              @mouseleave="hoverRow(`#${index} > td`, 'mouseleave')"
            >
              <!-- Only allow highlighting for QVCreditPersonalLoan -->
              <span v-if="currentProduct === 'qvCreditPersonalLoan'">{{ tableHeaderLabel(index) }}</span>
              <span v-else :data-content="tableHeaderLabel(index)" />
            </th>
            <!-- Application Data -->
            <td
              v-for="(app) in apps"
              :key="app._id"
              class="table-content"
              :class="`${app._id}-col`"
              @mouseover="hoverCol(`${app._id}-col`, 'mouseover')"
              @mouseleave="hoverCol(`${app._id}-col`, 'mouseleave')"
            >
              <partner-comment
                v-if="index === 'partnerComment'"
                :id="app._id"
                :comment="app.partnerComment"
                :field-toggle="partnerCommentStatus[app._id]"
              />
              <appointment-booking
                v-else-if="index === 'appointmentDateTime'"
                :id="app._id"
                :date-time="app.appointmentDateTime"
                :appointment-address="app.appointmentAddress"
                :appointment-missed="!!app.appointmentMissed"
                :field-toggle="appointmentBookingStatus[app._id]"
                :disable="false"
              />
              <appointment-documents
                v-else-if="index === 'appointmentDocuments'"
                :id="app._id"
                :field-name="index"
                :selected-documents="app[index]"
                :field-toggle="appointmentDocumentsStatus[app._id]"
              />
              <appointment-address v-else-if="index === 'appointmentAddress'" :id="app._id" :field-name="index" :appointment-address="app[index]" :field-toggle="appointmentAddressStatus[app._id]" />
              <field-display v-else-if="index === 'address'" :id="app._id" :field-name="index" :field-val="app[index]" :additional-data="{ personalAddress: app.personalAddress, address: app.address, residentialStatus: app.residentialStatus }" />
              <field-display v-else-if="index === 'birthYear'" :id="app._id" :field-name="index" :field-val="app[index]" :additional-data="{ birthMonth: app.birthMonth }" />
              <field-display v-else-if="index === 'myInfoData'" :id="app._id" :field-name="index" :field-val="app.dataSource" :additional-data="{ showHasAdditionalMyinfoData: app.showHasAdditionalMyinfoData }" />
              <field-display
                v-else
                :id="app._id"
                :field-name="index"
                :field-val="app[index]"
                @showUploadedFiles="showUploadedFiles"
              />
            </td>
          </tr>
        </template>
      </tbody>

      <tfoot>
        <!-- CTA Panel -->
        <!-- Orange Credit's Final Offer/Disbursed field separation -->
        <template v-if="currentProduct === 'orangeCreditPersonalLoan'">
          <tr>
            <!-- Final Offer text -->
            <td class="table-header">{{ tableHeaderLabel('orange_disburseLoan') }}</td>
            <td v-for="app in apps" :key="app._id" class="cta-panel" rowspan="2">
              <div>
                <acknowledged-panel :app-id="app._id" :appointment-missed="!!(app.appointmentMissed)" :appointment-date-time="app.appointmentDateTime" :panel-status.sync="panelStatus" />
              </div>
            </td>
          </tr>
          <tr v-if="panelStatus">
            <!-- Disbursed text -->
            <td class="table-header" style="height: 126.5px">{{ tableHeaderLabel('orange_disburseLoanSubText') }}</td>
          </tr>
        </template>

        <!-- Every other lender -->
        <template v-else>
          <tr>
            <td class="table-header" :data-content="tableHeaderLabel('disburseLoan')" />
            <td v-for="app in apps" :key="app._id" class="cta-panel">
              <div>
                <acknowledged-panel :app-id="app._id" :appointment-missed="!!(app.appointmentMissed)" :appointment-date-time="app.appointmentDateTime" :panel-status.sync="panelStatus" />
              </div>
            </td>
          </tr>
        </template>
      </tfoot>
    </table>

    <div v-else class="cleared-section-wrapper">
      <cleared-table />
    </div>

    <reject-app-modal stage="acknowledged" />
    <disburse-app-modal />
    <cancel-appointment-modal />
    <missed-appointment-modal />
    <export-apps v-if="allowExport" />
  </div>
</template>

<script>
import acknowledgedPanel from '../acknowledgedPanel';
import tableMixin from '../../mixins/cssMixin/tableMixin';
import rejectAppModal from '../rejectAppModal';
import disburseAppModal from '../disburseAppModal';
import cancelAppointmentModal from '../cancelAppointmentModal';
import missedAppointmentModal from '../missedAppointmentModal.vue';
import fieldDisplay from '../fieldDisplay';
import exportApps from '../exportApps.vue';
import reviseOfferInfoVue from '../reviseOfferInfo.vue';
import copyFieldMixin from '../../mixins/copyFieldMixin';

import { useAppStore } from '../../store/app';

export default {
  components: {
    'acknowledged-panel': acknowledgedPanel,
    'reject-app-modal': rejectAppModal,
    'disburse-app-modal': disburseAppModal,
    'cancel-appointment-modal': cancelAppointmentModal,
    'missed-appointment-modal': missedAppointmentModal,
    'field-display': fieldDisplay,
    'export-apps': exportApps,
    'revise-offer-info': reviseOfferInfoVue,
  },

  mixins: [tableMixin, copyFieldMixin],

  props: {
    apps: Array,
    fields: Object,
  },

  data () {
    return {
      panelStatus: false,
      appStore: useAppStore(),
    }
  },

  computed: {
    allowExport () {
      return this.appStore.allowExport;
    },
    currentProduct () {
      return this.appStore.currentProduct;
    }
  },

  methods: {
    showUploadedFiles (applicationId) {
      const routeData = this.$router.resolve({ name: 'file-downloads', query: { id: applicationId } });
      window.open(routeData.href, '_blank');
    },
  },
}
</script>

<style scoped>
table > thead td:first-child::before {
  content: attr(data-content);
}

table > tbody td:first-child::before {
  content: attr(data-content);
}
table > tbody div::before {
  content: attr(data-content);
}
table > tbody span::before {
  content: attr(data-content);
}
table > tbody p::before {
  content: attr(data-content);
}

table > tfoot th:first-child::before {
  content: attr(data-content);
}
</style>
