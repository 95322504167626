<template>
  <div class="input-wrapper" :class="(error[id]) ? 'error-div' : ''">
    <div> {{ label }} ({{ getPrefix(prefix.prefixFront) || getPrefix(prefix.prefixBack) || '' }}) </div>
    <div style="background-color: transparent; padding: 2px 5px;">
      <div class="input-grid">
        <div> <input v-model="theModel" :placeholder="((prefix.prefixFront) ? getPrefix(prefix.prefixFront) : getPrefix(prefix.prefixBack)).replace(' ', '')" @focus="thousandConcatenator()" @blur="onBlurred()"> </div>
      </div>
      <div v-if="error[id]" id="error-text"> {{ getTexts('invalidInput') }} </div>
      <div v-else-if="warning[id]" id="warning-text"> {{ getTexts('inputWarningText') }} </div>
    </div>
  </div>
</template>

<script>
import { resolveIf } from '../../mixins/helper';
import { useAppStore } from '../../store/app';
import { useDomainStore } from '../../store/domain';
import { useNuxtStore } from '../../store/nuxt';

export default {
  props: {
    id: String,
    var: Object,
    label: String,
    error: Object,
    prefix: Object,
    warning: Object,
    onChange: Array,
    appId: String,
    productDefault: Object,
  },

  data () {
    return {
      appStore: useAppStore(),
      domainStore: useDomainStore(),
      nuxtStore: useNuxtStore(),
    }
  },

  computed: {
    miscTexts () {
      return this.domainStore.miscTexts();
    },
    prefixes () {
      return this.domainStore.prefixes();
    },
    theModel: {
      get () {
        if (!this.var[this.id]) return this.var[this.id];
        return this.var[this.id];
      },
      set (val) {
        if (val === '') {
          this.var[this.id] = '';
        }
        else if (this.validateField(this.id, val)) {
          this.var[this.id] = val;
          this.error[this.id] = false;
        } else {
          // daheck is this
          this.var[this.id] = this.var[this.id];
        }

        if (this.onChange) this.onChange.forEach(f => f(this.var, this.appId));
        this.$forceUpdate();
      }
    },
    locale () {
      return this.nuxtStore.locale;
    }
  },

  mounted () {
    this.var.currentProduct = this.appStore.currentProduct;

    if (this.productDefault) {
      if (this.productDefault.if) {
        const { field, values, defaultTo, doDefault } = this.productDefault.if;
        if (resolveIf(this.var[field], values)) {
          if (defaultTo && !this.var[this.id]) {
            this.var[this.id] = defaultTo;
          }
          // if (doDefault);
        }
      }
    }
  },

  methods: {
    getPrefix (prefix) {
      if (this.prefixes[prefix]) return this.prefixes[prefix].label;
      return prefix;
    },
    getTexts (index) {
      if (this.miscTexts[index]) return this.miscTexts[index].label;
      return index;
    },
    validateField (field, val) {
      switch (field) {
        case 'loanPeriod':
          if (String(val).match(/^[0-9]*$/)) return true;
          return false;

        case 'appliedAmount':
        case 'processingFee':
        case 'monthlyCost':
          if (String(val).match(/^[0-9,]+\.?[0-9]*$/)) return true;
          return false;

        default:
          if (String(val).match(/^[0-9]+\.?[0-9]*$/)) return true;
          return false;
      }
    },
    onBlurred () {
      const val = this.theModel;
      if (!val && val !== 0) return;

      const validations = {
        // trigger warning if...
        hk: {
          annualRate: { above: 60, is: 0 },
          interestRate: { above: 3 },
          loanPeriod: { above: 72 },
          processingFee: { above: 10 },
        },
        sg: {
          interestRate: { above: 48, below: 10, not: 0 }
        },
        au: {
          interestRate: { above: 48, below: 10, not: 0 }
        },
      };

      const validation = validations[this.locale];

      if ((this.id === 'annualRate' || this.id === 'interestRate' || this.id === 'processingFee') && (val > 0 && val < 1)) {
        this.warning[this.id] = true;
      }
      else if (
        validation[this.id] &&
        ((
          Number(val) > validation[this.id].above ||
          Number(val) < validation[this.id].below ||
          Number(val) === validation[this.id].is
        )) &&
        val !== validation[this.id].not
      ) {
        this.warning[this.id] = true;
      }
      else this.warning[this.id] = false; // reset warning flag if !warning

      if (this.id !== 'loanPeriod' && this.id !== 'interestRate') {
        this.theModel = this.thousandSeparator(this.theModel);
      }
    },

    thousandSeparator (value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },

    thousandConcatenator () {
      if (this.theModel && typeof this.theModel === 'string') this.theModel = Number(this.theModel.replace(/,/g, ''));
    },

    numberOnly () {
      const regex = this.decimals ? /[^0-9.]/g : /[^0-9]/g;
      this.theModel = this.theModel.replace(regex, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0');
    }
  }
}
</script>

<style scoped>
.input-wrapper {
  display: grid;
  grid-template-columns: 50% 1fr;
  grid-column-gap: 15px;
  margin: 5px 0px 8px;
  align-items: baseline;
}

.input-grid {
  display: grid;
  grid-template-columns: 1fr max-content;
  /* display: flex; */
  color: white;
  font-size: 0.86rem;
}

.input-grid > div:not(:last-child) {
  margin-right: 5px;
}

input {
  width: 100%;
  border: none;
  /* background-color: transparent; */
  /* border-bottom: 1px solid white; */
  text-align: right;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type=number]::placeholder {
  text-align: left;
}
</style>
