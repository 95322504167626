<template>
  <div style="width: 100%">
    <div v-if="giveIPAPanel" class="bottom-spacer">
      <div v-for="(field, index) in fields" :key="index">
        <number
          v-if="field.type === 'number'"
          :id="index"
          :key="index"
          :var="offerObj"
          :label="getFieldLabel(index)"
          :error="errorFields"
          :warning="warningFields"
          :prefix="field.prefix"
          :on-change="field.onChange"
          :app-id="appId"
          :product-default="field.productDefault"
        />
        <checkbox
          v-else-if="field.type === 'checkbox'"
          :id="index"
          :var="offerObj"
          :label="getFieldLabel(index)"
          :error="errorFields"
          :warning="warningFields"
        />
        <readonly
          v-else-if="field.type === 'readOnly'"
          :id="index"
          :var="offerObj"
          :prefix="field.prefix"
          :label="getFieldLabel(index)"
          :default-value="field.defaultValue"
        />
        <inputWarning
          :display-condition="index === 'appliedAmount' && locale === 'sg' && isExceedingCap()"
        >
          {{ miscTexts.loanAmountCapMessage && miscTexts.loanAmountCapMessage.label.replace('%loanAmountCap%', offerObj.appliedAmount ? offerObj.appliedAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : offerObj.appliedAmount) }}
        </inputWarning>
      </div>
      <!-- Documents reviewed check -->
      <checkbox
        v-if="app.uploads"
        id="documentsReviewed"
        :var="offerObj"
        :error-var="errorFields"
        :label="getTexts('docReviewed')"
        :default="false"
        style="margin-bottom: 20px; font-weight: 600; color: #FFFFFF;"
      />
    </div>

    <div v-if="declineAppPanel" :class="(rejectReasonError) ? 'error-div' : ''" class="bottom-spacer">
      <div :class="(rejectReasonError) ? 'error-div' : ''">
        <div id="ipa-offer-wrapper">
          <div v-if="locale === 'sg'">
            <div>
              <div>{{ getTexts('rejectCategory') }}</div>
              <select v-model="rejectCategoryModel">
                <option v-for="option in getDeclineOptions" :key="option.value" :value="option.value">
                  {{ option.text }}
                </option>
              </select>
            </div>
            <div>
              <div>{{ getTexts('rejectSubCategory') }}</div>
              <select v-model="rejectReasonModel" :disabled="hasRejectCategory">
                <option v-for="option in getDeclineSubCategory" :key="option.value" :value="option.value">
                  {{ option.text }}
                </option>
              </select>
            </div>
          </div>
          <div v-if="locale === 'hk'">
            <div>
              <div>{{ getTexts('rejectReason') }}</div>
              <select v-model="rejectReasonModel">
                <option v-for="option in getDeclineOptions" :key="option.value" :value="option.value">
                  {{ option.text }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div v-if="rejectReasonError" id="error-text">{{ getTexts('rejectReasonError') }}</div>
      </div>
      <div :class="(rejectReasonOtherError) ? 'error-div' : ''">
        <div style="margin-top: 10px;">
          <div class="label">{{ locale === 'sg' ? getTexts('remarks') : getTexts('otherRejectReason') }}</div>
          <textarea v-model="otherRejectReasonModel" style="width: 100%;resize: none" />
          <div v-if="rejectReasonOtherError" id="error-text">{{ getTexts('rejectReasonError') }}</div>
        </div>
      </div>
      <!-- Documents reviewed check -->
      <checkbox
        v-if="app.uploads"
        id="documentsReviewed"
        :var="offerObj"
        :error-var="errorFields"
        :label="getTexts('docReviewed')"
        :default="false"
        style="margin-bottom: 20px; font-weight: 600; color: #FFFFFF;"
      />
    </div>

    <div class="cta-buttons-flexbox">
      <button v-if="!declineAppPanel" class="green-button sharp-corner-button" @click="submitOffer()"> {{ getButtonLabel('giveIPABtn') }} </button>
      <button v-if="!giveIPAPanel" class="red-button sharp-corner-button" @click="decline()"> {{ getButtonLabel('declineAppBtn') }} </button>
      <button v-if="expanded" class="light-grey-button sharp-corner-button" @click="collapsePanel()"> {{ getButtonLabel('cancelBtn') }} </button>
    </div>
  </div>
</template>

<script>
import EventBus from '../js/eventBus';
import offerPanelMixin from '../mixins/offerPanelMixin';
import partnerPortalAPI from '../mixins/apiServices/partnerPortal';
import number from './inputFields/number';
import checkbox from './inputFields/checkbox';
import readonly from './inputFields/readOnly';
import inputWarning from './inputFields/inputWarning.vue';
import infoTooltip from './infoTooltip.vue';

import { useDomainStore } from '../store/domain';

export default {
  components: {
    number,
    checkbox,
    readonly,
    inputWarning,
    infoTooltip,
  },
  mixins: [partnerPortalAPI, offerPanelMixin],
  props: {
    appId: String,
    app: Object,
  },
  data () {
    return {
      giveIPAPanel: false,
      declineAppPanel: false,
      expanded: false,
      panel: 'lead',
      domainStore: useDomainStore(),
    }
  },
  computed: {
    miscTexts () {
      return this.domainStore.miscTexts();
    },
    hasRejectCategory () {
      // eslint-disable-next-line no-undef
      return !(this.rejectCategoryModel);
    },
  },
  mounted () {
    EventBus.$on(`update-offer-var-${this.appId}`, this.updateOfferOnApp);
    this.offerObj.documentsReviewed = this.getDocumentsReviewedValue();

    this.$watch('rejectReason', (newVal) => {
      if (newVal !== 'other') this.rejectReasonOtherError = false;
    });
  },
  unmounted () {
    EventBus.$off(`update-offer-var-${this.appId}`, this.updateOfferOnApp);
  },
  methods: {
    showGiveIPAPanel () {
      this.giveIPAPanel = true;
      this.expanded = true;
    },
    showDeclineAppPanel () {
      this.declineAppPanel = true;
      this.expanded = true;
    },
    collapsePanel () {
      this.giveIPAPanel = false;
      this.declineAppPanel = false;
      this.expanded = false;
    },
    submitOffer () {
      if (!this.giveIPAPanel) {
        return this.showGiveIPAPanel();
      }

      if (!this.docsReviewed()) return;
      if (!this.validateOfferObj()) return;

      // submit
      EventBus.$emit('give-ipa', {
        appId: this.appId,
        ipaOffer: this.offerObj,
        product: this.currentProduct,
        warning: this.warningFields,
      });
    },
    decline () {
      if (!this.declineAppPanel) {
        return this.showDeclineAppPanel();
      }
      if (!this.docsReviewed()) return;
      if (!this.validateRejectReason()) return;

      const emitObj = {
        appId: this.appId,
        product: this.currentProduct,
        otherReason: this.rejectReasonOther
      }

      if (this.locale === 'hk') {
        const d = this.rejectTexts.find(d => d.value === this.rejectReason);
        const internalReason = (this.rejectReasonOther) ? `${this.rejectReason} - ${this.rejectReasonOther}` : this.rejectReason;
        emitObj.reason = this.rejectReason;
        emitObj.reasonTxt = d.value ? d.text : undefined;
        emitObj.internalRejectReason = (this.offerObj.documentsReviewed) ? `After doc review: ${internalReason}` : internalReason;
      }

      if (this.locale === 'sg') {
        const categoryCopy = this.rejectTexts.find(d => d.value === this.rejectCategory);
        const subCategoryCopy = this.rejectSubCategory.find(category => category.value === this.rejectReason);
        const texts = { categoryCopy, subCategoryCopy }
        const internalReason = (this.rejectReasonOther) ? `${this.rejectCategory} - ${this.rejectReason} - ${this.rejectReasonOther}` : `${this.rejectCategory} - ${this.rejectReason}`;

        const declinedReasonData = {
          category: this.rejectCategory,
          subCategory: this.rejectReason,
          remark: this.rejectReasonOther,
          isDocReviewed: this.offerObj.documentsReviewed,
        }
        emitObj.reason = declinedReasonData;
        emitObj.reasonTxt = texts;
        emitObj.internalRejectReason = (this.offerObj.documentsReviewed) ? `After doc review: ${internalReason}` : internalReason;
      };

      EventBus.$emit('decline-app', emitObj);
    },
    docsReviewed () {
      if (this.offerObj.documentAcknowledgementStages) return true;

      if (this.app.uploads && !this.offerObj.documentsReviewed) {
        this.errorFields.documentsReviewed = true;
        return false;
      }

      return true;
    },
    isExceedingCap () {
      const { monthlyIncome = 0, existingLoanML = 0 } = this.app;
      const appliedAmount = typeof this.offerObj.appliedAmount === 'string' ? Number(this.offerObj.appliedAmount.replace(/,/g, '')) : Number(this.offerObj.appliedAmount) ? Number(this.offerObj.appliedAmount) : 0;

      return ((Number(appliedAmount) + Number(existingLoanML)) / Number(monthlyIncome)) > 6;
    },
    getDocumentsReviewedValue () {
      const { offer } = this.app;
      if (!Object.prototype.hasOwnProperty.call(offer, 'documentAcknowledgementStages')) return false;

      return offer.documentAcknowledgementStages.includes('lead');
    },
    validateRejectReason () {
      if (!this.rejectReason) {
        this.rejectReasonError = true;
        return false;
      }

      if (this.rejectCategory && !this.rejectSubCategory) {
        this.rejectReasonOtherError = true;
        return false;
      }

      if ((this.rejectReason === 'other' || (this.rejectCategory === 'lender_policy' && this.rejectReason === 'others')) && (!(/\S/.test(this.rejectReasonOther)) || this.rejectReasonOther === undefined)) {
        this.rejectReasonOtherError = true;
        return false;
      }

      return true;
    },
    
    updateOfferOnApp ({ field, val }) {
      this.offerObj[field] = val;
      this.$forceUpdate();
    }
  },
}
</script>

<style scoped>
table, div {
  font-size: 0.86rem;
}

input {
  width: 100%;
}

table tr > td:first-child {
  width: 60%;
}

table tr > td:not(:first-child) {
  width: 40%;
}

button {
  margin: 0 !important;
}

.input-container > span {
  color: #FFFFFF;
}
</style>
