<template>
  <div>
    <div class="info-icon text-center" @click="showDetails = !showDetails">
      <span class="text-white">i</span>
    </div>
    <span v-if="showDetails" class="bubble">
      <div class="inner-arrow" />
      <span class="close-button" @click="showDetails = false">
        <img src="../assets/img/cross.svg" alt="close" width="18" height="18">
      </span>
      <div style="text-align:left">
        {{ personalNumber }}

        <div style="margin-top: 15px">{{ getLabel('previousRejectionDate') }}:</div>
        <div style="font-weight: normal">{{ formatDateTime(rejectionData.timestamp) }}</div>

        <div style="margin-top: 15px">{{ getLabel('previousRejectionReason') }}:</div>
        <div style="font-weight: normal">{{ getRejectReasonsLabel(rejectionData.reason) }}</div>
      </div>
    </span>
  </div>
</template>

<script>
import moment from 'moment';

import { useDomainStore } from '../store/domain';

export default {
  props: {
    rejectionData: Object,
    personalNumber: String,
  },

  data () {
    return {
      showDetails: false,
      domainStore: useDomainStore(),
    }
  },

  computed: {
    miscTexts () {
      return this.domainStore.miscTexts();
    },
    rejectionReasons () {
      return this.domainStore.rejectionReasons();
    },
    rejectionCategories () {
      return this.domainStore.rejectionCategories();
    },
    rejectionSubCategories () {
      return this.domainStore.rejectionSubCategories();
    },
  },

  methods: {
    formatDateTime (datetime) {
      return moment(datetime).format('MMM DD YYYY, h:mm a');
    },

    getLabel (field) {
      if (this.miscTexts[field]) return this.miscTexts[field].label;
      return field;
    },

    getRejectReasonsLabel (str) {
      let index = str;

      if (typeof index === 'string') {
        index = index.replace('After doc review: ', '');
        if (this.rejectionReasons[index]) {
          return this.rejectionReasons[index].label;
        } else {
          return index;
        }
      }

      if (typeof index === 'object') {
        const { category, subCategory, remark, isDocReviewed } = index;
        const rejectionCategoryLabel = this.rejectionCategories[category].label || category;
        const rejectionSubCategoryLabel = this.rejectionSubCategories[subCategory].label || subCategory;
        const declineReason = (isDocReviewed) ? `After doc review: ${rejectionCategoryLabel}: ${rejectionSubCategoryLabel}` : `${rejectionCategoryLabel}: ${rejectionSubCategoryLabel}`;
        const fullDeclineReason = (remark) ? `${declineReason} - ${remark}` : declineReason;
        return fullDeclineReason;
      }
    },
  },
}
</script>

<style scoped>
.info-icon {
  width: 18px;
  height: 18px;
  background: red;
  cursor: pointer;
  font-size: 0.86rem;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bubble {
  position: absolute;
  width: 90%;
  background: #FFFFFF;
  border-radius: 10px;
  padding: 24px;
  color: #000;
  top: 40px;
  right: -1%;
  box-shadow: 1px 0px 4px 0px #bdbdbd;
}

div.inner-arrow {
  background: #FFFFFF;
  position: absolute;
  width: 15px;
  height: 15px;
  transform: rotate(45deg);
  top: -8px;
  right: 5%;
  z-index: 0;
  border-left: 1px solid #e1dbdb;
  border-top: 1px solid #e1dbdb;
}

.bubble > .close-button {
  position: absolute;
  height: 15px;
  width: 15px;
  top: 15px;
  right: 20px;
  cursor: pointer;
}
</style>
