<template>
  <div>
    <p v-if="displayCondition" style="color: yellow"><slot /></p>
  </div>
</template>

<script>
import { useDomainStore } from '../../store/domain';

export default {
  props: {
    displayCondition: Boolean,
  },

  data () {
    return {
      domainStore: useDomainStore(),
    }
  },

  computed: {
    miscTexts () {
      return this.domainStore.miscTexts();
    },
    prefixes () {
      return this.domainStore.prefixes();
    },
  }
}
</script>

<style scoped>
</style>
