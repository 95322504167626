import tableMixin from './cssMixin/tableMixin';

export default {
  mixins: [tableMixin],
  methods: {
    async copyFieldMixin_copy (cellData) {
      await navigator.clipboard.writeText(cellData);
      const alt = document.createElement('div');
      alt.setAttribute('class', 'copy-popup');
      alt.innerHTML = this.tableHeaderLabel('copiedText');
      setTimeout(function () {
        alt.parentNode.removeChild(alt);
      }, 1500);
      document.body.appendChild(alt);
    }
  }
};
