<template>
  <div>
    <appointment-documents-field v-if="fieldToggle" :id="id" :selected-documents="selectedDocuments" />
    <div v-else title="Edit Appointment Documents" @click="toggleDocumentList()">
      <div v-if="selectedDocuments.length === 0">N/A</div>
      <ul v-else-if="currentProduct === 'qvCreditPersonalLoan' && currentTab === 'acknowledged'" class="appointment-doc-list">
        <li v-for="doc in selectedDocuments" :key="doc">{{ getDocumentText(doc) }}</li>
      </ul>
      <ul v-else class="appointment-doc-list">
        <li v-for="doc in selectedDocuments" :key="doc" :data-content="getDocumentText(doc)" />
      </ul>
    </div>
  </div>
</template>

<script>
import EventBus from '../../js/eventBus';
import appointmentDocumentsField from './appointmentDocumentsField';

import { useAppStore } from '../../store/app';
import { useDomainStore } from '../../store/domain';

export default {
  props: {
    id: String,
    selectedDocuments: Array,
    fieldToggle: Boolean,
  },

  components: {
    'appointment-documents-field': appointmentDocumentsField
  },

  data () {
    return {
      appStore: useAppStore(),
      domainStore: useDomainStore(),
    }
  },

  computed: {
    appointmentDocuments () {
      return this.domainStore.appointmentDocuments();
    },
    currentProduct () {
      return this.appStore.currentProduct;
    },
    currentTab () {
      return this.appStore.currentTab;
    }
  },

  methods: {
    getDocumentText (docKey) {
      const docText = this.appointmentDocuments.find(doc => doc.key === docKey);
      if (docText && docText.value) return docText.value
      return docKey;
    },

    toggleDocumentList () {
      EventBus.$emit('toggle-appointment-documents', { id: this.id, show: true });
    }
  }
}
</script>

<style scoped>
li::before {
  content: attr(data-content);
}
</style>
