<template>
  <div v-if="disable">
    {{ (dateTime) ? formatAppointmentTime(dateTime) : '' }}
  </div>
  <div v-else-if="fieldToggle">
    <appointment-booking-field :id="id" :appointment-date-time="dateTime" :appointment-address="appointmentAddress" />
  </div>
  <div
    v-else
    class="partner-appointment-field"
    :class="(!dateTime) ? 'cursor-text' : 'cursor-text editable'"
    :title="(!dateTime) ? 'Add Appointment' : 'Edit Appointment'"
    @click="$parent.appointmentBookingToggle({id, show: true})"
  >
    <span v-if="!dateTime && !disable"> {{ $parent.getButtonLabel('addAppointment') }} </span>
    <div v-else-if="$parent.appStore.currentProduct === 'qvCreditPersonalLoan' && $parent.appStore.currentTab === 'acknowledged'">
      {{ formatAppointmentTime(dateTime) }}
      <span v-if="appointmentMissed">({{ $parent.getMiscTextLabel('missedLabel') }})</span>
    </div>
    <div v-else :data-content="formatAppointmentTime(dateTime)">
      <span v-if="appointmentMissed">({{ $parent.getMiscTextLabel('missedLabel') }})</span>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import appointmentBookingField from './appointmentBookingField';

export default {
   props: {
    id: String,
    dateTime: String,
    appointmentAddress: String,
    fieldToggle: Boolean,
    disable: Boolean,
    appointmentMissed: Boolean,
  },

  components: {
    'appointment-booking-field': appointmentBookingField
  },

  methods: {
    formatAppointmentTime (datetime) {
      if (!localStorage.getItem('language')) return moment(datetime).format('MMMM DD YYYY, h:mm a');
      else return localStorage.getItem('language') === 'EN' ? moment(datetime).format('MMMM DD YYYY, h:mm a') : moment(datetime).format('YYYY年M月D日 h:mmA');
    },
  }

}
</script>
