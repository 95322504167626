<template>
  <div v-if="modal" class="modal-container">
    <div class="modal-content">
      <div id="cpf-user-info">
        <div style="display: flex; flex-direction: row; justify-content: space-between;">
          <h2>{{ getLabel('myInfoData') }}</h2>
          <img src="../assets/img/cross.svg" alt="close" class="close-button" @click="modal = false">
        </div>

        <div style="font-weight: bold; margin: 5px 0">
          <div v-if="getApp.firstName" class="content-block">
            <p class="content-key">{{ getLabel('firstName') }}</p>
            <p class="content-value">{{ getApp.firstName }}</p>
          </div>
          <div v-if="getApp.personalNumber" class="content-block">
            <p class="content-key">{{ getLabel('personalNumber') }}</p>
            <p class="content-value">{{ getApp.personalNumber }}</p>
          </div>
          <div v-if="retrieving" class="text-center space-top">
            <i class="loader" />
          </div>
        </div>
      </div>

      <div style="overflow: auto">
        <template v-if="cpfData">
          <div class="myinfo-table-subcontainer">
            <div class="myinfo-table-title">
              <h3 style="text-align:left;"> {{ getLabel('cpfContributions') }} </h3>
              <img
                src="../assets/img/chevron.svg"
                alt="chevron"
                class="myinfo-chevron"
                :class="{ 'open': showCpf }"
                @click="showCpf = !showCpf"
              >
            </div>

            <div v-if="showCpf">
              <div class="div-table-container">
                <div class="div-table">
                  <div class="div-row div-row-header">
                    <div v-for="header in tableHeaders" :key="header" class="div-cell" style="padding: 5px 0 5px 10px;">
                      <div style="display: flex; justify-content: space-between;">
                        <p style="padding-right: 10px; margin: 0">{{ getLabel(`cpf_${header}`) }}</p>
                        <div id="right-border" />
                      </div>
                    </div>
                  </div>
                  <div v-for="(item, index) in cpfData" :key="item.date + index" class="div-row">
                    <div v-for="header in tableHeaders" :key="header" class="div-cell">
                      <template v-if="header === 'amount'">
                        ${{ item[header] }}
                      </template>
                      <template v-else>
                        {{ item[header] }}
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>

        <template v-if="noaData">
          <div class="myinfo-table-subcontainer">
            <div class="myinfo-table-title">
              <h3 style="text-align: left;">{{ getLabel('noaTitle') }}</h3>
              <img
                src="../assets/img/chevron.svg"
                alt="chevron"
                class="myinfo-chevron"
                :class="{ 'open': showNoa }"
                @click="showNoa = !showNoa"
              >
            </div>

            <div v-if="showNoa" class="myinfo-table-content">
              <div style="display: flex; flex-direction: row-reverse">
                <template v-for="(data, index) in Object.values(noaData)" :key="index">
                  <div style="width: 50%" class="noa-table">
                    <div v-if="data.year" :key="index + data.year" style="padding: 10px 15px">
                      <span class="text-heavy">{{ getLabel('yearOfAssessment') }}: </span>{{ data.year }}
                    </div>
                    <div v-if="data.type" :key="index + data.type" style="padding: 10px 15px">
                      <span class="text-heavy">{{ getLabel('noaType') }}: </span>{{ capitalizeFirstLetter(data.type) }}
                    </div>
                    <div v-if="data.assessableIncome" :key="index + data.assessableIncome" style="padding: 10px 15px">
                      <span class="text-heavy">{{ getLabel('noaAssessableIncome') }}: </span>${{ data.assessableIncome }}
                    </div>
                    <div v-if="data.incomeBreakdown" :key="index + data.incomeBreakdown.employment" style="padding: 10px 15px">
                      <p class="text-heavy">{{ getLabel('incomeBreakdown') }}</p>
                      <ul>
                        <template v-for="(incomeData, incomeIndex) in Object.keys(data.incomeBreakdown)">
                          <li v-if="incomeData === 'employment'" :key="incomeIndex">
                            {{ getLabel('noaEmployment') }}: {{ (data.incomeBreakdown[incomeData] && (data.incomeBreakdown[incomeData] != 0 && data.incomeBreakdown[incomeData] != undefined)) ? `$${data.incomeBreakdown[incomeData]}` : '-' }}
                          </li>
                          <li v-if="incomeData === 'trade'" :key="incomeIndex">
                            {{ getLabel('noaTrade') }}: {{ (data.incomeBreakdown[incomeData] && (data.incomeBreakdown[incomeData] != 0 && data.incomeBreakdown[incomeData] != undefined)) ? `$${data.incomeBreakdown[incomeData]}` : '-' }}
                          </li>
                          <li v-if="incomeData === 'rent'" :key="incomeIndex">
                            {{ getLabel('noaRent') }}: {{ (data.incomeBreakdown[incomeData] && (data.incomeBreakdown[incomeData] != 0 && data.incomeBreakdown[incomeData] != undefined)) ? `$${data.incomeBreakdown[incomeData]}` : '-' }}
                          </li>
                          <li v-if="incomeData === 'interest'" :key="incomeIndex">
                            {{ getLabel('noaInterest') }}: {{ (data.incomeBreakdown[incomeData] && (data.incomeBreakdown[incomeData] != 0 && data.incomeBreakdown[incomeData] != undefined)) ? `$${data.incomeBreakdown[incomeData]}` : '-' }}
                          </li>
                        </template>
                      </ul>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </template>

        <template v-if="myInfoData && (myInfoData.passType || myInfoData.passStatus || myInfoData.passExpiry || myInfoData.employmentSector)">
          <div class="myinfo-table-subcontainer">
            <div class="myinfo-table-title">
              <h3 style="text-align: left;">{{ getLabel('workPassTitle') }}</h3>
              <img
                src="../assets/img/chevron.svg"
                alt="chevron"
                class="myinfo-chevron"
                :class="{ 'open': showEmpAndWorkPass }"
                @click="showEmpAndWorkPass = !showEmpAndWorkPass"
              >
            </div>

            <div v-if="showEmpAndWorkPass" class="myinfo-table-content">
              <div v-if="myInfoData.passType" class="content-block">
                <p class="content-value text-heavy">{{ getLabel('passType') }}</p>
                <p class="content-key">{{ getPassType(myInfoData.passType) }}</p>
              </div>
              <div v-if="myInfoData.passStatus" class="content-block">
                <p class="content-value text-heavy">{{ getLabel('passStatus') }}</p>
                <p class="content-key">{{ myInfoData.passStatus }}</p>
              </div>
              <div v-if="myInfoData.passExpiry" class="content-block">
                <p class="content-value text-heavy">{{ getLabel('passExpiry') }}</p>
                <p class="content-key">{{ formatDate(myInfoData.passExpiry) }}</p>
              </div>
              <div v-if="myInfoData.employmentSector" class="content-block">
                <p class="content-value text-heavy">{{ getLabel('employmentSector') }}</p>
                <p class="content-key">{{ myInfoData.employmentSector }}</p>
              </div>
            </div>
          </div>
        </template>

        <template v-if="Object.keys(hdbOwnershipData).length > 0">
          <div class="myinfo-table-subcontainer">
            <div class="myinfo-table-title">
              <h3 style="text-align: left;">{{ getLabel('hdbOwnershipTitle') }}</h3>
              <img
                src="../assets/img/chevron.svg"
                alt="chevron"
                class="myinfo-chevron"
                :class="{ 'open': showHdbOwnership }"
                @click="showHdbOwnership = !showHdbOwnership"
              >
            </div>

            <div v-if="showHdbOwnership" class="myinfo-table-content">
              <div v-if="hdbOwnershipData.outstanding" class="content-block">
                <p class="content-value text-heavy">{{ getLabel('hdbOutstandingBalance') }}</p>
                <p class="content-key">${{ thousandSeparator(hdbOwnershipData.outstanding) }}</p>
              </div>
              <div v-if="hdbOwnershipData.monthlyInstalment" class="content-block">
                <p class="content-value text-heavy">{{ getLabel('hdbMonthlyInstalment') }}</p>
                <p class="content-key">${{ thousandSeparator(hdbOwnershipData.monthlyInstalment) }}</p>
              </div>
              <div v-if="hdbOwnershipData.type" class="content-block">
                <p class="content-value text-heavy">{{ getLabel('hdbDwellingType') }}</p>
                <p class="content-key">{{ hdbOwnershipData.type }}</p>
              </div>
              <div v-if="hdbOwnershipData.purchaseDate" class="content-block">
                <p class="content-value text-heavy">{{ getLabel('hdbPurchaseDate') }}</p>
                <p class="content-key">{{ formatDate(hdbOwnershipData.purchaseDate) }}</p>
              </div>
            </div>
          </div>
        </template>

        <template v-if="getApp.ownsProperty">
          <div class="myinfo-table-subcontainer">
            <div class="myinfo-table-title">
              <h3 style="text-align: left;">{{ getLabel('privatePropertyOwnershipTitle') }}</h3>
              <img
                src="../assets/img/chevron.svg"
                alt="chevron"
                class="myinfo-chevron"
                :class="{ 'open': showPrivatePropertyOwnership }"
                @click="showPrivatePropertyOwnership = !showPrivatePropertyOwnership"
              >
            </div>

            <div class="myinfo-table-content">
              <div class="content-block">
                <p class="content-value text-heavy">{{ getLabel('privatePropertyOwnershipType') }}</p>
                <p class="content-key">{{ resolveDomain(resolveOwnsProperty, 'ownsProperty') }}</p>
              </div>
            </div>
          </div>
        </template>

        <template v-if="Object.keys(cpfHousingData).length > 0">
          <div class="myinfo-table-subcontainer">
            <div class="myinfo-table-title">
              <h3 style="text-align: left;">{{ getLabel('cpfHousingTitle') }}</h3>
              <img
                src="../assets/img/chevron.svg"
                alt="chevron"
                class="myinfo-chevron"
                :class="{ 'open': showCpfHousingData }"
                @click="showCpfHousingData = !showCpfHousingData"
              >
            </div>

            <div v-if="showCpfHousingData" class="myinfo-table-content">
              <div v-if="cpfHousingData.principalWithdrawal" class="content-block">
                <p class="content-value text-heavy">{{ getLabel('cpfPrincipleWithdrawal') }}</p>
                <p class="content-key">{{ cpfHousingData.principalWithdrawal }}</p>
              </div>
              <div v-if="cpfHousingData.monthlyInstalment" class="content-block">
                <p class="content-value text-heavy">{{ getLabel('cpfMonthlyInstalment') }}</p>
                <p class="content-key">{{ cpfHousingData.monthlyInstalment }}</p>
              </div>
              <div v-if="cpfHousingData.accruedInterest" class="content-block">
                <p class="content-value text-heavy">{{ getLabel('cpfAccruedInterest') }}</p>
                <p class="content-key">{{ cpfHousingData.accruedInterest }}</p>
              </div>
              <div v-if="cpfHousingData.amountOfCpfAllowedForProperty" class="content-block">
                <p class="content-value text-heavy">{{ getLabel('cpfAllowedForProperty') }}</p>
                <p class="content-key">{{ cpfHousingData.amountOfCpfAllowedForProperty }}</p>
              </div>
            </div>
          </div>
        </template>

        <template v-if="vehiclesData">
          <div class="myinfo-table-subcontainer">
            <div class="myinfo-table-title">
              <h3 style="text-align: left;">{{ getLabel('vehiclesTitle') }}</h3>
              <img
                src="../assets/img/chevron.svg"
                alt="chevron"
                class="myinfo-chevron"
                :class="{ 'open': showVehiclesData }"
                @click="showVehiclesData = !showVehiclesData"
              >
            </div>

            <div v-if="showVehiclesData" class="myinfo-table-content">
              <div class="content-block">
                <p class="content-value text-heavy">{{ getLabel('vehiclesEffectiveDate') }}</p>
                <p class="content-key">{{ vehiclesData }}</p>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import EventBus from '../js/eventBus';
import applicationMixin from '../mixins/apiServices/application';

import { useAppStore } from '../store/app';
import { useDomainStore } from '../store/domain';

export default {
  mixins: [applicationMixin],

  data  () {
    return {
      modal: false,
      id: '',
      cpfData: null,
      myInfoData: null,
      retrieving: false,
      showCpf: true,
      noaData: null,
      showNoa: true,
      showEmpAndWorkPass: true,
      hdbOwnershipData: {},
      showHdbOwnership: true,
      showPrivatePropertyOwnership: true,
      cpfHousingData: {},
      showCpfHousingData: true,
      vehiclesData: null,
      showVehiclesData: true,
      appStore: useAppStore(),
      domainStore: useDomainStore(),
    }
  },

  computed: {
    contentText () {
      return this.domainStore.contentText();
    },
    domains () {
      return this.domainStore.getDomains();
    },
    mappedDomains () {
      return this.domainStore.getMappedDomains();
    },
    tableHeaders () {
      return Object.keys(this.cpfData[0]) || [];
    },
    getApp () {
      const { currentTab, currentProduct, apps: allApps } = this.appStore;
      const apps = allApps[currentProduct][currentTab];
      const currentApp = apps.find(app => app._id === this.id);

      return currentApp || {};
    },
    resolveOwnsProperty () {
      if (['ownsPrivateProperty', 'ownsMultipleProperties'].includes(this.getApp.ownsProperty)) {
        return 'yes'
      } else {
        return 'no'
      }
    }
  },

  mounted () {
    EventBus.$on('view-myinfodata', this.viewMyInfoData);
  },

  unmounted () {
    EventBus.$off('view-myinfodata', this.viewMyInfoData);
  },

  methods: {
    getLabel (field) {
      if (this.contentText[field]) return this.contentText[field].label;
      return field;
    },

    formatDate (date) {
      return moment(date).format('DD/MM/YYYY')
    },

    capitalizeFirstLetter (string) {
      return string.charAt(0) + string.slice(1).toLowerCase();
    },

    checkIfUndefined (val) {
      return val === undefined;
    },

    thousandSeparator (value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },

    async getMyinfoData (appId) {
      this.retrieving = true;

      const getData = await this.getApplicationMyinfoData(appId);

      if (getData.responseCode === 200) {
        this.myInfoData = getData.data.data;
        this.cpfData = this.myInfoData.cpfContributions;
        this.noaData = this.myInfoData.noticeOfAssessment;
        this.hdbOwnershipData.outstanding = this.myInfoData.hdbOutstandingLoan;
        this.hdbOwnershipData.monthlyInstalment = this.myInfoData.hdbMonthlyInstallment;
        this.hdbOwnershipData.type = this.myInfoData.hdbOwnershipType;
        this.hdbOwnershipData.purchaseDate = this.myInfoData.hdbOwnershipPurchaseDate;
        this.cpfHousingData.principalWithdrawal = this.myInfoData.cpfWithdrawalPrincipleAmount;
        this.cpfHousingData.monthlyInstalment = this.myInfoData.cpfWithdrawalMonthlyInstalment;
        this.cpfHousingData.accruedInterest = this.myInfoData.cpfWithdrawalAccruedAmount;
        this.cpfHousingData.amountOfCpfAllowedForProperty = this.myInfoData.cpfWithdrawalAmountAllowedForProperty
        this.vehiclesData = this.myInfoData.vehicles;
      }

      if (Object.values(this.hdbOwnershipData).every(this.checkIfUndefined)) this.hdbOwnershipData = {};
      if (Object.values(this.cpfHousingData).every(this.checkIfUndefined)) this.cpfHousingData = {};

      this.retrieving = false;
    },

    getPassType (code) {
      const pre = [
        { code: 'DP', value: 'Dependent Pass' },
        { code: 'Entre', value: 'EntrePass' },
        { code: 'LOC', value: 'Letter of Consent' },
        { code: 'LTVP', value: 'Long Term Visit Pass' },
        { code: 'LTVP+', value: 'Long Term Visit Pass +' },
        { code: 'MWP', value: 'Miscellaneous Work Pass' },
        { code: 'P1Pass', value: 'Employment Pass' },
        { code: 'P2Pass', value: 'Employment Pass' },
        { code: 'PEP', value: 'Personalised Employment Pass' },
        { code: 'QPass', value: 'Employment Pass' },
        { code: 'RPass', value: 'Work Permit' },
        { code: 'SPass', value: 'S Pass' },
        { code: 'STP', value: 'Short Term Pass' },
        { code: 'TEP', value: 'Training Employment Pass' },
        { code: 'WHP', value: 'Work Holiday Pass' },
      ];
      if (!pre.find(c => c.code === code)) return '';
      return pre.find(c => c.code === code).value
    },

    resolveDomain (fieldVal, fieldName) {
      const fieldDomain = this.mappedDomains[fieldName];
      if (!fieldDomain) return fieldVal;
      if (!this.domains[fieldDomain]) return fieldVal;
      const optionsList = this.domains[fieldDomain];

      const foundVal = Object.keys(optionsList).find(option => optionsList[option].value === String(fieldVal));
      return (foundVal) ? optionsList[foundVal].text : fieldVal;
    },
    
    viewMyInfoData (payload) {
      this.id = payload.id;
      this.myInfoData = null;
      this.cpfData = null;
      this.getMyinfoData(payload.id);
      this.modal = true;
    }
  }
};
</script>

<style scoped>
.text-heavy {
  font-weight: bold;
}
.modal-content {
  width: 800px !important;
  text-align: initial !important;
}

.content-block {
  display: flex;
  flex-direction: row;
}

.content-block .content-key {
  width: 300px;
}

.content-block .content-value {
  color: black;
}

.myinfo-table-container {
  background: #f8f8f8;
  border-radius: 6px;
  overflow: auto;
  border: 1px solid #E3E3E3;
}

.myinfo-table-subcontainer {
  background: white;
  border-radius: 6px;
  box-shadow: 1px 6px 14px -8px rgba(0,0,0,0.55);
  -webkit-box-shadow: 1px 6px 14px -8px rgba(0,0,0,0.55);
  -moz-box-shadow: 1px 6px 14px -8px rgba(0,0,0,0.55);
}

.myinfo-table-subcontainer:not(:first-child) {
  margin-top: 20px;
}

.myinfo-table-subcontainer:last-child {
  margin-bottom: 10px;
}

.myinfo-table-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #eeeeee;
  padding: 10px 15px;
  border-radius: 6px 6px 0 0;
}

.myinfo-table-content {
  background-color: #f8f8f8;
}

.myinfo-table-content .content-key, .myinfo-table-content .content-value {
  width: 50%;
  padding: 10px 15px;
}

.noa-table {
  color: black;
}

.noa-table .content-value {
  font-weight: bold;
}

.noa-table div:nth-child(odd) {
  background-color: #e0e0e0;
  border-right: 1px solid white;
}

.noa-table div:nth-child(even) {
  background-color: white;
  border-right: 1px solid #e0e0e0;
}

.noa-table ul {
  padding-left: 10px;
}

.noa-table li {
  list-style: none;
}

li:before {
    content:"· ";
    font-size:24px;
    vertical-align:middle;
    line-height:20px;
}

.div-table-container {
  justify-content: center;
  overflow: auto;
  max-height: 30vh;
  border-radius: 0 0 6px 6px;
}

.div-table-container .div-row-header .div-cell {
  background: #E3E3E3 !important;
  color: #212121 !important;
}

.div-table-container .div-row-header .div-cell:not(:last-child) #right-border {
  background: white;
  width: 1px;
  margin-right: -1px;
}

.div-table-container .div-row .div-cell {
  background: white;
  color: #6A7984;
}

.div-table-container .div-cell {
  border: 1px solid #E3E3E3;
}

.myinfo-chevron {
  filter: invert(57%) sepia(58%) saturate(513%) hue-rotate(73deg) brightness(94%) contrast(88%);
  transform: rotate(0deg);
  transition: transform  0.2s linear;
}

.myinfo-chevron.open {
  transform: rotate(180deg);
  transition: transform  0.2s linear;
}

@media only screen and (min-width: 769px) {
  .modal-content {
    max-height: 75% !important;
    max-width: 60% !important;
  }
}

@media only screen and (max-width: 768px) {
  .modal-content .div-cell {
    white-space: nowrap;
  }

  .modal-content {
    max-height: 80% !important;
  }
}
</style>
