<template>
  <div class="input-wrapper">
    <div> {{ label }} </div>
    <div style="background-color: transparent; padding: 2px 5px;">
      <div class="input-grid">
        <div>
          {{ (prefix.prefixFront) ? getPrefix(prefix.prefixFront) : '' }}
          {{ theModel }}
          {{ (prefix.prefixBack) ? getPrefix(prefix.prefixBack) : '' }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useDomainStore } from '../../store/domain';
import { useNuxtStore } from '../../store/nuxt';

export default {
  props: {
    id: String,
    var: Object,
    label: String,
    prefix: Object,
    defaultValue: Number,
  },

  data () {
    return {
      domainStore: useDomainStore(),
      nuxtStore: useNuxtStore(),
    }
  },

  computed: {
    miscTexts () {
      return this.domainStore.miscTexts();
    },
    prefixes () {
      return this.domainStore.prefixes();
    },
    theModel: {
      get () {
        if (this.var[this.id] === undefined) return 0;
        if (this.id === 'nettLoanAmount') {
          return this.var.nettLoanAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        }
        return Number(this.var[this.id]);
      },
    },
    locale () {
      return this.nuxtStore.locale;
    }
  },

  mounted () {
    if (this.defaultValue && this.var[this.id] !== undefined) {
      this.var[this.id] = this.defaultValue;
    }
  },
  methods: {
    getPrefix (prefix) {
      if (this.prefixes[prefix]) return this.prefixes[prefix].label;
      return prefix;
    },
  }
}
</script>

<style scoped>
.input-wrapper {
  display: grid;
  grid-template-columns: 50% 1fr;
  grid-column-gap: 15px;
  margin: 5px 0px 8px;
  align-items: baseline;
}

.input-grid {
  display: grid;
  grid-template-columns: 1fr max-content;
  /* display: flex; */
  color: white;
  font-size: 0.86rem;
}

.input-grid > div:not(:last-child) {
  margin-right: 5px;
}

input {
  width: 100%;
  border: none;
  /* background-color: transparent; */
  /* border-bottom: 1px solid white; */
  text-align: right;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
</style>
