<template>
  <div id="applications-wrapper" style="margin-top:-30px">
    <div>
      <div class="tabs-bar" style="margin-top:20px">
        <div
          v-for="(type, tab) of apps"
          :key="tab"
          :class="(currentTab === tab) ? 'active-tab' : ''"
          @click="changeTab(tab)"
        >
          <div class="tab-content">
            <div> {{ (tabLabels[tabs[tab].id]) ? tabLabels[tabs[tab].id].label : tab }} </div>
          </div>
        </div>
      </div>
    </div>

    <apps-table :apps="apps[currentTab] || []" :fields="getFields" :tab="currentTab" />
  </div>
</template>

<script>
import closedAppsTable from '../closedAppsTable';

import { useAppStore } from '../../store/app';
import { useDomainStore } from '../../store/domain';

export default {
  components: {
    'apps-table': closedAppsTable,
  },
  props: {
    apps: Object
  },
  data () {
    return {
      tabs: {
        leadRejected: { id: 'leadRejectedApp' },
        rejected: { id: 'rejectedApp' },
        disbursed: { id: 'disbursedApp' },
      },
      appStore: useAppStore(),
      domainStore: useDomainStore(),
    }
  },
  computed: {
    tabLabels () {
      return this.domainStore.tabLabels();
    },
    currentTab () {
      return this.appStore.closedCurrentTab;
    },
    getFields () {
      return (this.appStore.fields && this.appStore.currentProduct && this.currentTab) ? (this.appStore.fields[this.appStore.currentProduct][this.currentTab]) : {};
    },
  },
  mounted () {},
  methods: {
    changeTab (tab) {
      this.appStore.setAppStore('closedCurrentTab', tab);
    },
    sectionLength (section) {
      const length = this.apps[section].length;
      return (length) || 0;
    },
  },
}
</script>
