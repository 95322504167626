<template>
  <div>
    <div v-for="(document, index) in appointmentDocuments" :key="`doc-input-${index}`" class="appointment-doc-option">
      <div>
        <label class="input-container">
          <span> {{ document.value }} </span>
          <input :id="document.key" :key="`doc-input-${index}`" v-model="documentList" :value="document.key" type="checkbox">
          <span class="checkmark" />
        </label>
      </div>
    </div>
    <button class="sharp-corner-button" :class="(disableButton) ? 'disabled-button' : 'green-button'" :disabled="disableButton" @click="postAppointmentDocuments()"> {{ getButtonLabel('updateAppointmentDocuments') }} </button>
    <button class="light-grey-button sharp-corner-button" @click="toggleEdit(false)"> {{ getButtonLabel('cancelBtn') }} </button>
  </div>
</template>

<script>
import EventBus from '../../js/eventBus';
import partnerPortalApi from '../../mixins/apiServices/partnerPortal';

import { useAppStore } from '../../store/app';
import { useDomainStore } from '../../store/domain';

export default {
  mixins: [partnerPortalApi],

  props: {
    id: String,
    selectedDocuments: Array
  },

  data () {
    return {
      documentList: this.selectedDocuments,
      appStore: useAppStore(),
      domainStore: useDomainStore(),
    }
  },

  computed: {
    appointmentDocuments () {
      return this.domainStore.appointmentDocuments();
    },
    
    miscButtonLabels () {
      return this.domainStore.miscButtonLabels();
    },

    disableButton () {
      if (this.selectedDocuments.every(doc => this.documentList.includes(doc)) && this.documentList.every(doc => this.selectedDocuments.includes(doc))) return true;
      return false;
    }
  },

  methods: {
    toggleEdit (show) {
      EventBus.$emit('toggle-appointment-documents', { id: this.id, show });
    },

    async postAppointmentDocuments () {
      this.error = false;
      const postObject = {
        appId: this.id,
        documents: this.documentList,
        product: this.currentProduct
      };

      const { data, responseCode } = await this.updateAppointmentDocuments(postObject);
      if (responseCode === 200) {
        this.appStore.updateAppointmentDocuments(this.id, this.documentList)
        return this.toggleEdit(false);
      }

      this.error = true;
    },

    getButtonLabel (index) {
      if (this.miscButtonLabels[index]) return this.miscButtonLabels[index].label;
      return index;
    },
  }
}
</script>

<style scoped>
  .input-container {
    display: block;
    position: relative;
    padding-left: 24px;
    cursor: pointer;
    margin: 5px 0px 5px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: fit-content;
  }

input {
  display: none;
  }

  /* custom checkbox */
  .checkmark {
    position: absolute;
    top: 2px;
    left: 0;
    height: 17px;
    width: 17px;
    background-color: white;
    border-radius: 3px;
    border: 1px solid #a7b1b6;
  }

  /* On mouse-over, add a grey background color */
  .input-container:hover input ~ .checkmark {
    background-color: #eee;
  }

  /* When the checkbox is checked, add a green background */
  .input-container input:checked ~ .checkmark {
    background-color: #43B649;
    border: none;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .input-container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .input-container .checkmark:after {
    left: 6px;
    top: 1px;
    width: 6px;
    height: 11px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
</style>
