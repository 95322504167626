<template>
  <div v-if="modal" class="modal-container">
    <div class="modal-content">
      <img src="../assets/img/cross.svg" alt="close" class="close-button" @click="(status === 'success') ? refreshApp() : (closeDialog())">

      <div v-if="status === 'pendingConfirmation'" class="model-content-wrapper">
        <h2>{{ getTexts('ignoreRevisionHeader') }}</h2>
        <button class="yellow-button" @click="confirmedNoChange()"> {{ getButtonLabel('ignoreRevisionBtn') }} </button>
        <button class="light-grey-button" @click="closeDialog()"> {{ getButtonLabel('cancelBtn') }} </button>
      </div>

      <div v-else-if="status === 'conflict'">
        <h2 class="space-bottom">{{ getTexts('concurrencyHeader') }}</h2>
        <p class="space-bottom"> {{ getTexts('concurrencyError') }} </p>
        <button class="green-button" @click="refreshApp()">{{ getButtonLabel('refreshBtn') }}</button>
      </div>

      <div v-else-if="status === 'error'">
        <h2 class="space-bottom">{{ getTexts('errorHeader') }}</h2>
        <p class="space-bottom">{{ statusMessage }}</p>
        <button class="red-button" @click="closeDialog()">{{ getButtonLabel('closeBtn') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import partnerPortalAPI from '../mixins/apiServices/partnerPortal';
import offerPanelMixin from '../mixins/offerPanelMixin';
import EventBus from '../js/eventBus';

export default {
  mixins: [partnerPortalAPI, offerPanelMixin],

  props: {
    subStage: String,
  },

  data () {
    return {
      modal: false,
      appId: '',
      product: '',
      processingReq: false,
      status: 'pendingConfirmation',
      statusMessage: '',
      newApps: {},
    }
  },

  mounted () {
    EventBus.$on('ignore-revise-ipa', this.ignoreReviseIPAEvent);
  },

  unmounted () {
    EventBus.$off('ignore-revise-ipa', this.ignoreReviseIPAEvent);
  },

  methods: {
    closeDialog () {
      this.modal = false;
    },
    async confirmedNoChange () {
      this.processingReq = true;
      await this.ignoreReviseIPA(this.appId, this.product, this.subStage).then(({ responseCode, data }) => {
        if ([200, 409].includes(responseCode) && Object.keys(data)[0] === this.currentProduct) {
          this.newApps = data;
          if (responseCode === 200) {
            this.processingReq = false;
            return this.refreshApp();
          }

          // If not 200, then it is 409
          this.status = 'conflict';
          this.processingReq = false;
          return;
        }

        // If error
        this.status = 'error';
        this.statusMessage = data;
        this.processingReq = false;
      });
    },

    refreshApp () {
      EventBus.$emit('refreshApp', this.newApps);
      this.modal = false;
    },

    ignoreReviseIPAEvent (payload) {
      this.modal = true;
      this.appId = payload.appId;
      this.product = payload.product;
    }
  },
}
</script>
