<template>
  <div v-if="modal" class="modal-container">
    <div class="modal-content">
      <img src="../assets/img/cross.svg" alt="close" class="close-button" @click="(status === 'success') ? refreshApp() : closeModal()">

      <div v-if="status === 'pendingConfirmation'" class="model-content-wrapper">
        <h2> {{ getTexts('giveOfferPopupHeader') }} </h2>
        <p class="space-bottom text-bold"><u>{{ app.firstName }} ({{ app[UserViewMixin_appIdentifier] }})</u></p>
        <div class="offer-details">
          <div>
            <div v-for="field in list" :key="field" class="offer-flexbox">
              <strong> {{ fieldLabel(field) }}: </strong>
            </div>
          </div>
          <div>
            <div v-for="field in list" :key="field" class="offer-flexbox">
              {{ fieldData(field, offer[field]) }}
              <span v-if="warning[field]" id="warning-text-dark"> ({{ getTexts('inputWarningText') }}) </span>
            </div>
          </div>
        </div>

        <button class="green-button" :disabled="processingReq" @click="submitOffer()">{{ getButtonLabel('giveIpaBtn') }}</button>
        <button class="red-button" @click="closeModal()">{{ getButtonLabel('cancelBtn') }}</button>
      </div>

      <div v-else-if="status === 'success'">
        <h2 class="space-bottom">{{ statusHeader }}</h2>
        <p class="space-bottom"> {{ statusMessage }}</p>
        <button class="green-button" @click="refreshApp()">{{ getButtonLabel('refreshBtn') }}</button>
      </div>

      <div v-else-if="status === 'error'">
        <h2 class="space-bottom">{{ getTexts('errorHeader') }}</h2>
        <p class="space-bottom">{{ statusMessage }}</p>
        <button class="red-button" @click="closeModal()">{{ getButtonLabel('closeBtn') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '../js/eventBus';
import partnerPortalAPI from '../mixins/apiServices/partnerPortal';
import displayOfferMixin from '../mixins/displayOfferMixin';
import userViewSettingsMixin from '../mixins/userViewSettingsMixin';

import { useAppStore } from '../store/app';
import { useDomainStore } from '../store/domain';

export default {
  mixins: [partnerPortalAPI, displayOfferMixin, userViewSettingsMixin],

  props: {
    subStage: String,
  },

  data  () {
    return {
      offerFunnel: 'pre-disburse',
      modal: false,
      status: 'pendingConfirmation',
      statusHeader: '',
      statusMessage: '',
      offer: {},
      warning: {},
      newApps: {},
      appId: '',
      panel: undefined,
      appStore: useAppStore(),
      domainStore: useDomainStore(),
    }
  },

  computed: {
    miscTexts () {
      return this.domainStore.miscTexts();
    },
    miscButtonLabels () {
      return this.domainStore.miscButtonLabels();
    },
    app () {
      const { currentProduct, apps, currentTab } = this.appStore;
      const currentAppList = apps[currentProduct][currentTab];
      return currentAppList.find(a => a._id === this.appId);
    },
  },

  mounted () {
    EventBus.$on('give-ipa', this.giveIPAEvent);
  },

  unmounted () {
    EventBus.$off('give-ipa', this.giveIPAEvent);
  },

  methods: {
    getTexts (index) {
      if (this.miscTexts[index]) return this.miscTexts[index].label;
      return index;
    },
    getButtonLabel (index) {
      if (this.miscButtonLabels[index]) return this.miscButtonLabels[index].label;
      return index;
    },
    async submitOffer () {
      this.processingReq = true;
      await this.giveIPA(this.appId, this.offer, this.currentProduct, this.panel, this.subStage).then(({ responseCode, data }) => {
        if ((responseCode === 200 || responseCode === 409) && Object.keys(data)[0] === this.currentProduct) {
          this.status = 'success';
          this.newApps = data;
          if (responseCode === 200) {
            this.statusHeader = this.getTexts('successHeader');
            this.statusMessage = this.getTexts('ipaSuccessMessage');
          } else {
            this.statusHeader = this.getTexts('concurrencyHeader');
            this.statusMessage = this.getTexts('concurrencyError');
          }
          this.processingReq = false;
          return;
        }

        // if error
        this.status = 'error';
        this.statusMessage = data;
        this.processingReq = false;
      });
    },
    refreshApp () {
      EventBus.$emit('refreshApp', this.newApps);
      this.modal = false;
    },
    closeModal () {
      if (Object.keys(this.offer).length > 0) {
        Object.keys(this.offer).forEach((i) => {
          if (['appliedAmount', 'processingFee', 'monthlyCost', 'nettLoanAmount'].includes(i) && typeof this.offer[i] === 'number') {
            this.offer[i] = this.offer[i].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          }
        })
      }
      this.modal = false;
    },

    giveIPAEvent (payload) {
      this.modal = true;
      this.offer = payload.ipaOffer;
      this.appId = payload.appId;
      this.warning = payload.warning;
      this.panel = payload.panel;

      // reset
      this.status = 'pendingConfirmation';
      this.statusMessage = '';
      this.newApps = {};
    }
  }
};
</script>
