<template>
  <div>
    {{ getTexts('clearedSection') }}
  </div>
</template>

<script>
import { useDomainStore } from '../../store/domain';

export default {
  data () {
    return {
      domainStore: useDomainStore(),
    }
  },

  computed: {
    miscTexts () {
      return this.domainStore.miscTexts();
    }
  },
  
  methods: {
    getTexts (index) {
      if (this.miscTexts[index]) return this.miscTexts[index].label;
      return index;
    },
  }
}
</script>
