import moment from 'moment';
import EventBus from '../../js/eventBus';
import partnerComment from '../../components/inputFields/partnerComment';
import appointmentBooking from '../../components/inputFields/appointmentBooking';
import appointmentDocuments from '../../components/inputFields/appointmentDocuments';
import appointmentAddress from '../../components/inputFields/appointmentAddress';
import uploadDocuments from '../../components/inputFields/uploadDocuments';
import clearedTable from '../../components/subPages/clearedTable';
import displayOfferMixin from '../displayOfferMixin';
import userViewSettingsMixin from '../userViewSettingsMixin';

import { useAppStore } from '../../store/app';
import { useNuxtStore } from '../../store/nuxt';
import { useDomainStore } from '../../store/domain';

export default {
  components: {
    'cleared-table': clearedTable,
    'partner-comment': partnerComment,
    'appointment-booking': appointmentBooking,
    'appointment-documents': appointmentDocuments,
    'appointment-address': appointmentAddress,
    'upload-documents': uploadDocuments,
  },

  mixins: [displayOfferMixin, userViewSettingsMixin],

  computed: {
    domains () {
      return this.domainStore.getDomains();
    },
    mappedDomains () {
      return this.domainStore.getMappedDomains();
    },
    contentText () {
      return this.domainStore.contentText();
    },
    miscButtonLabels () {
      return this.domainStore.miscButtonLabels();
    },
    miscTexts () {
      return this.domainStore.miscTexts();
    },
    languageLabels () {
      return this.domainStore.languageLabels();
    },
    isMobile () {
      return (this.nuxtStore.windowWidth <= 768);
    },
    nuxtApp () {
      return this.nuxtStore;
    },
    language () {
      return this.nuxtStore.language;
    },
  },
  data () {
    return {
      color: {
        mouseover: '#fafafa',
        mouseleave: '',
      },
      partnerCommentStatus: {},
      appointmentBookingStatus: {},
      appointmentAddressStatus: {},
      appointmentDocumentsStatus: {},
      uploadDocumentsStatus: {},
      appStore: useAppStore(),
      domainStore: useDomainStore(),
      nuxtStore: useNuxtStore(),
    }
  },

  mounted () {
    // window.addEventListener('resize', this.windowResized);
    // this.windowResized();

    EventBus.$on('toggle-partner-comment', this.partnerCommentToggle);

    EventBus.$on('toggle-appointment-booking', this.appointmentBookingToggle);

    EventBus.$on('toggle-appointment-address', this.appointmentAddressToggle);

    EventBus.$on('toggle-appointment-documents', this.appointmentDocumentsToggle);
    EventBus.$on('toggle-upload-documents', this.uploadDocumentsToggle);
  },
  unmounted () {
    EventBus.$off('toggle-partner-comment', this.partnerCommentToggle);
    EventBus.$off('toggle-appointment-booking', this.appointmentBookingToggle);
    EventBus.$off('toggle-appointment-address', this.appointmentAddressToggle);
    EventBus.$off('toggle-appointment-documents', this.appointmentDocumentsToggle);
    EventBus.$off('toggle-upload-documents', this.uploadDocumentsToggle);
  },
  methods: {
    partnerCommentToggle ({ id, show }) {
      this.partnerCommentStatus[id] = show;
    },
    appointmentBookingToggle ({ id, show }) {
      this.appointmentBookingStatus[id] = show;
    },
    appointmentAddressToggle ({ id, show }) {
      this.appointmentAddressStatus[id] = show;
    },
    appointmentDocumentsToggle ({ id, show }) {
      this.appointmentDocumentsStatus[id] = show;
    },
    uploadDocumentsToggle ({ id, show }) {
      this.uploadDocumentsStatus[id] = show;
    },
    showCpfModal (id, cpfData) {
      EventBus.$emit('view-cpf', { id, cpfData });
    },
    showMyinfoModal (id) {
      EventBus.$emit('view-myinfodata', { id });
    },
    showDebtInfoModal (id, debtInfoData) {
      EventBus.$emit('view-debtinfodata', { id, debtInfoData });
    },
    fieldType (fieldName) {
      const { fields, currentProduct, currentTab } = this.appStore;
      const tab = (this.tab) ? this.tab : currentTab;

      return fields[currentProduct][tab][fieldName].fieldtype;
    },
    getButtonLabel (index) {
      if (this.miscButtonLabels[index]) return this.miscButtonLabels[index].label;
      return index;
    },
    getLanguageLabel (index) {
      if (this.languageLabels[index]) return this.languageLabels[index].label;
      return index;
    },
    getMiscTextLabel (index) {
      if (this.miscTexts[index]) return this.miscTexts[index].label;
      return index;
    },
    tableHeaderLabel (fieldID) {
      if (this.contentText[fieldID]) return this.contentText[fieldID].label;
      return fieldID;
    },
    windowResized () {
      // document.body.height = window.innerHeight;
      this.nuxtStore.resetDimensions();
      // this.mobileColumnSizing();

      const tableWrapper = document.getElementById('tableWrapper');
      if (tableWrapper) tableWrapper.style['max-height'] = `${window.innerHeight - tableWrapper.offsetTop - 62}px`;
    },
    hoverCol (cssQuery, event) {
      const hoveredApp = document.getElementsByClassName(cssQuery);

      for (let e = 0; e < hoveredApp.length; e += 1) {
        hoveredApp[e].style.background = this.color[event];
      }
    },
    hoverRow (cssQuery, event) {
      const hoveredRow = document.querySelectorAll(cssQuery);

      for (let e = 0; e < hoveredRow.length; e += 1) {
        hoveredRow[e].style.background = this.color[event];
      }
    },
    // mobileColumnSizing () {
    //   if (this.isMobile) {
    //     // const stickyColumnWidth = 100;
    //     // const stickyColumns = document.querySelectorAll('th:first-child');
    //     // if (stickyColumns) {
    //     //   stickyColumns.forEach((column) => {
    //     //     column.style.width = `${stickyColumnWidth}px`;
    //     //     column.style.fontSize = '12px';
    //     //   });
    //     // }
    //   } else this.desktopColumnSizing();
    // },
    // desktopColumnSizing () {
    //   const stickyColumns = document.querySelectorAll('th:first-child');
    //   if (stickyColumns) {
    //     stickyColumns.forEach((column) => {
    //       column.style.width = '100px';
    //       column.style.fontSize = '12px';
    //     });
    //   }
    //   const secondaryColumns = document.querySelectorAll('th:not(:first-child), td:not(:first-child)');
    //   for (let e = 0; e < secondaryColumns.length; e += 1) {
    //     secondaryColumns[e].style.width = '270px';
    //   }
    // },
    intToRGB (i) {
      const c = (i & 0x00FFFFFF)
        .toString(16)
        .toUpperCase();

      return '00000'.substring(0, 6 - c.length) + c;
    },
    hashCode (str) {
      let hash = 0;
      for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      return hash;
    },
    pickTextColorBasedOnBgColorAdvanced (bgColor) {
      const color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
      const r = parseInt(color.substring(0, 2), 16); // hexToR
      const g = parseInt(color.substring(2, 4), 16); // hexToG
      const b = parseInt(color.substring(4, 6), 16); // hexToB
      const uicolors = [r / 255, g / 255, b / 255];
      const c = uicolors.map((col) => {
        if (col <= 0.03928) return col / 12.92;
        return ((col + 0.055) / 1.055) ** 2.4;
      });
      const L = (0.2126 * c[0]) + (0.7152 * c[1]) + (0.0722 * c[2]);
      return (L > 0.179) ? 'black' : 'white';
    },
    formatField (fieldType, fieldVal, fieldName) {
      const momentLocale = (this.language === 'EN') ? 'en' : 'zh-hk';
      moment.locale(momentLocale);

      switch (fieldType) {
        case 'date-time':
          return (this.language === 'EN') ? moment(fieldVal).format('LLL') : moment(fieldVal).format('YYYY年M月D日 h:mmA');

        case 'date':
          return moment(fieldVal).format('DD/MM/YYYY');

        case 'long-date':
          return moment(fieldVal).format('MMMM DD, YYYY');

        case 'year-month':
          if (!fieldVal) return '';
          return (this.language === 'EN') ? moment(fieldVal).format('MMM YYYY') : moment(fieldVal).format('YYYY年MM月');

        case 'long-date-time':
          return (this.language === 'EN') ? moment(fieldVal).format('MMM D, YYYY h:mmA') : moment(fieldVal).format('YYYY年M月D日 h:mmA');

        case 'dropdown':
          return this.resolveDomain(fieldVal, fieldName);

        case 'monetary':
          return (fieldVal) ? `$${Number(fieldVal).toLocaleString()}` : fieldVal;

        default:
          return fieldVal;
      }
    },
    resolveLabel (field) {
      if (this.contentText[field]) return this.contentText[field].label;
      return field;
    },
    resolveDomain (fieldVal, fieldName) {
      const fieldDomain = this.mappedDomains[fieldName];
      if (!fieldDomain) return fieldVal;
      if (!this.domains[fieldDomain]) return fieldVal;

      const optionsList = this.domains[fieldDomain];
      // I added a hard code specifically for ownsCar because I didn't know how changing the logic completely would have an impact on the rest of code
      const foundVal = Object.keys(optionsList).find(option => optionsList[option].value === String(fieldVal) || (fieldName === 'ownsCar' && optionsList[option].value === fieldVal));
      return (foundVal) ? optionsList[foundVal].text : fieldVal;
    },
    formatCurrency (value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    // tableFillHeight () {
    //   const tableWrapper = document.getElementById('table-wrapper');
    //   tableWrapper.style.height = `${this.nuxtApp.windowHeight - tableWrapper.offsetTop}px`;
    // }
  }
}
