<template>
  <div v-if="modal" class="modal-container">
    <div class="modal-content">
      <div id="cpf-user-info">
        <div style="display: flex; flex-direction: row; justify-content: space-between;">
          <h2>{{ getLabel('debtInfo') }}</h2>
          <img src="../assets/img/cross.svg" alt="close" style="cursor: pointer;" @click="modal = false">
        </div>

        <div class="offer-expiry-container">
          <p class="offer-expiry-info">
            <img src="../assets/img/info.svg" width="24" height="24">
            <span>{{ getLabel('debtNoticeText') }}</span>
          </p>
        </div>

        <div style="font-weight: bold">
          <div style="display: flex; flex-direction: row;">
            <p style="width: 100px;">{{ getLabel('firstName') }}</p>
            <p style="color: black;">{{ getApp.firstName }}</p>
          </div>
          <div style="display: flex; flex-direction: row;">
            <p style="width: 100px;">{{ getLabel('personalNumber') }}</p>
            <p style="color: black;">{{ getApp.personalNumber }}</p>
          </div>
        </div>
      </div>

      <div class="debt-div-table-container">
        <div class="debt-div-table">
          <div class="debt-div-row-header">
            <div class="debt-div-cell">{{ getLabel('typeOfDebt') }}</div>
            <div v-if="(region === 'sg' && currentTab === 'acknowledged')" class="debt-div-cell">{{ getLabel('nameOfLender') }}</div>
            <div v-else class="debt-div-cell">{{ getLabel('nameOfLender') }}</div>
            <div class="debt-div-cell">{{ getLabel('outstandingAmount') }}</div>
            <div class="debt-div-cell">{{ getLabel('monthlyRepayment') }}</div>
          </div>
          <div v-for="(item, index) in debtInfoData" :key="index" class="debt-div-row">
            <div v-if="item.debtTypeManual" class="debt-div-cell">{{ getDebtType(item.debtType) }} - {{ item.debtTypeManual }}</div>
            <div v-else class="debt-div-cell">{{ getDebtType(item.debtType) }}</div>
            <div v-if="(region === 'sg' && currentTab === 'acknowledged')" class="debt-div-cell">{{ item.lenderName }}</div>
            <div v-else class="debt-div-cell">{{ item.lenderName }}</div>
            <div class="debt-div-cell">{{ item.outstandingAmount.toLocaleString() }}</div>
            <div class="debt-div-cell">{{ item.monthlyRepayment.toLocaleString() }}</div>
          </div>
          <div class="debt-div-row">
            <div class="debt-div-cell" style="border-bottom: none;" />
            <div v-if="(region === 'sg' && currentTab === 'acknowledged')" class="debt-div-cell" style="border-bottom: none;" />
            <div v-else class="debt-div-cell" style="border-bottom: none;" />
            <div class="debt-div-cell" style="color: black;">{{ totalOutstanding.toLocaleString() }}</div>
            <div class="debt-div-cell" style="color: black;">{{ totalMonthlyRepayment.toLocaleString() }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '../js/eventBus';

import { useAppStore } from '../store/app';
import { useDomainStore } from '../store/domain';
import { useNuxtStore } from '../store/nuxt';

export default {
  data () {
    return {
      modal: false,
      id: '',
      debtInfoData: null,
      appStore: useAppStore(),
      domainStore: useDomainStore(),
      nuxtStore: useNuxtStore(),
    }
  },
  computed: {
    contentText () {
      return this.domainStore.contentText();
    },
    domains () {
      return this.domainStore.getDomains();
    },
    getApp () {
      const { currentTab, currentProduct, apps: allApps } = this.appStore;
      const apps = allApps[currentProduct][currentTab];
      const currentApp = apps.find(app => app._id === this.id);

      return currentApp || {};
    },
    totalOutstanding () {
      let totalOutstanding = 0;

      totalOutstanding = this.debtInfoData.reduce(function (arr, curr) {
        return arr + curr.outstandingAmount;
      }, 0);

      return totalOutstanding;
    },
    totalMonthlyRepayment () {
      const totalRepayment = this.debtInfoData.reduce(function (arr, curr) {
        return arr + curr.monthlyRepayment;
      }, 0);

      return totalRepayment;
    },
    region () {
      return this.nuxtStore.locale; 
    },
    currentTab () {
      return this.appStore.currentTab;
    },
  },
  mounted () {
    EventBus.$on('view-debtinfodata', this.viewDebtInfoData);
  },
  unmounted () {
    EventBus.$off('view-debtinfodata', this.viewDebtInfoData);
  },
  methods: {
    getLabel (field) {
      if (this.contentText[field]) return this.contentText[field].label;
      return field;
    },
    getDebtType (debtKey) {
      const debtType = this.domains.debtTypes.find(type => type.value === debtKey);
      return debtType.text;
    },

    viewDebtInfoData (payload) {
      this.id = payload.id;
      this.debtInfoData = Object.entries(payload.debtInfoData)[0][1];
      this.modal = true;
    }
  }
}
</script>

<style scoped>
.modal-content {
  max-width: 700px !important;
  text-align: initial !important;
}
.offer-expiry-container {
  margin: 15px auto;
  max-width: 1300px;
}

.offer-expiry-info {
  color: #EDA825;
  border: 1px solid #EDA825;
  border-radius: 5px;
  padding: 10px;
  width: fit-content;
  display: flex;
  align-items: center;
}

.offer-expiry-info > img {
  padding: 0;
  border: 0;
  margin-right: 15px;
}

.debt-div-table-container {
  /* display: flex; */
  justify-content: center;
  margin-top: 15px;
  overflow: scroll;
  height: 420px;
}

.debt-div-table {
  display: table;
  border-collapse: collapse;
  width: 100%;
}

.debt-div-table-row {
  display: table-row-group;
}

.debt-div-row-header {
  font-weight: bold;
  background-color: #eeeeee;
  color: #39393F;
  display: table-row;
}

.debt-div-row-header .debt-div-cell {
  vertical-align: middle;
  padding: 10px 10px;
}

.debt-div-cell {
  display: table-cell;
  padding: 10px 10px;
  border-bottom: 1px solid white;
  color: black;
  width: 25%;
  font-weight: bold;
  font-size: 14px;
}

.debt-div-row {
  display: table-row;
}

.debt-div-row .debt-div-cell {
  border-bottom: 1px solid lightgray;
  color: #81888d;
}

</style>
