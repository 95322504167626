<template>
  <div class="download-button-float" @click="exportApps()">
    <img v-if="exporting" id="download-icon" class="float-img" src="../assets/logos/spinner-solid.svg">
    <img v-else id="download-icon" class="float-img" src="../assets/logos/download-solid.svg">
    <span class="tooltiptext">{{ getTexts('downloadAll') }}</span>
  </div>
</template>

<script>
import moment from 'moment';
import appMixin from '../mixins/apiServices/application';

import { useDomainStore } from '../store/domain';

export default {
  mixins: [appMixin],

  data () {
    return {
      exporting: false,
      domainStore: useDomainStore(),
    }
  },

  computed: {
    miscTexts () {
      return this.domainStore.miscTexts();
    }
  },

  methods: {
    async exportApps () {
      if (this.exporting) return;
      this.exporting = true;

      const response = await this.exportApplications();

      if (response.responseCode !== 200) {
        alert('Failed to export files');
        this.exporting = false;
        return;
      }

      const { data } = response;
      const timestamp = moment().format('YYYYMMDDHHmmss');
      const filename = `lendela_apps_${timestamp}.csv`;

      const fileURL = window.URL.createObjectURL(new Blob([data]));
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', filename);
      fileLink.click();

      this.exporting = false;
    },

    getTexts (index) {
      if (this.miscTexts[index]) return this.miscTexts[index].label;
      return index;
    },
  }
}
</script>

<style scoped>
.download-button-float {
  z-index: 5;
}

@media only screen and (min-width: 769px) {
  .download-button-float {
    right: 100px;
  }
}
</style>
