<template>
  <div class="input-wrapper" :class="(error[id]) ? 'error-div' : ''">
    <div> {{ label }} </div>
    <div class="input-div">
      <input v-model="theModel" type="date">
      <div v-if="error[id]" id="error-text"> {{ getTexts('invalidInput') }} </div>
      <div v-else-if="warning[id]" id="warning-text"> {{ getTexts('inputWarningText') }} </div>
    </div>
  </div>
</template>

<script>
import { useDomainStore } from '../../store/domain';

export default {
  props: {
    id: String,
    var: Object,
    label: String,
    error: Object,
    prefix: Object,
    warning: Object,
  },
  data () {
    return {
      domainStore: useDomainStore(),
    }
  },
  computed: {
    miscTexts () {
      return this.domainStore.miscTexts();
    },
    prefixes () {
      return this.domainStore.prefixes();
    },
    theModel: {
      get () {
        return this.var[this.id];
      },
      set (val) {
        this.var[this.id] = val;
      }
    }
  },
  methods: {
    getTexts (index) {
      if (this.miscTexts[index]) return this.miscTexts[index].label;
      return index;
    },
  }
}
</script>

<style scoped>
.input-wrapper {
  margin: 5px 0px 8px;
  align-items: baseline;
}

.input-div {
  padding-top: 5px;
  padding-right: 5px;
}

input {
  width: 100%;
  border: none;
}

</style>
