<template>
  <div style="position:relative">
    <div class="info-icon text-center" @mouseover="showDetails = true" @mouseleave="showDetails = false">
      <span class="text-white">i</span>
    </div>
    <span v-if="showDetails" class="bubble">
      {{ bodyText }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    bodyText: String,
  },
  data () {
    return {
      showDetails: false,
    }
  }
}
</script>

<style scoped>
.info-icon {
  margin-top: 3px;
  width: 18px;
  height: 18px;
  background: red;
  cursor: pointer;
  font-size: 0.86rem;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bubble {
  position: absolute;
  min-width: 250px;
  background: #ffffff;
  border-radius: 10px;
  padding: 10px;
  color: #000;
  bottom: 25px;
  left: -125px;
  box-shadow: 1px 1px 4px 0px #bdbdbd;
}

div.inner-arrow {
  background: white;
  position: absolute;
  width: 25px;
  height: 25px;
  transform: rotate(45deg);
  top: -10px;
  right: 4%;
  box-shadow: 1px 0px 9px 0px #bdbdbd;
  z-index: -1;
}
</style>
