<template>
  <div class="partner-comment-textarea">
    <textarea id="partner-comment-field" v-model="theModel" rows="5" :placeholder="getButtonLabel('addComment')" />
    <div v-if="error" class="error-message"> Save failed </div>
    <button class="green-button sharp-corner-button" @click="postComment()"> {{ getButtonLabel('saveCommentBtn') }} </button>
    <button class="light-grey-button sharp-corner-button" @click="toggleEdit(false)"> {{ getButtonLabel('discardCommentBtn') }} </button>
  </div>
</template>

<script>
import EventBus from '../../js/eventBus';
import partnerPortalApi from '../../mixins/apiServices/partnerPortal';

import { useAppStore } from '../../store/app';
import { useDomainStore } from '../../store/domain';

export default {
  mixins: [partnerPortalApi],
  props: {
    id: String,
    comment: String,
  },
  data () {
    return {
      commentModel: '',
      error: false,
      errorMessage: '',
      appStore: useAppStore(),
      domainStore: useDomainStore(),
    }
  },
  computed: {
    miscButtonLabels () {
      return this.domainStore.miscButtonLabels();
    },
    theModel: {
      get () {
        return this.commentModel;
      },
      set (val) {
        this.commentModel = val;
      }
    }
  },
  mounted () {
    this.theModel = this.comment;

    this.$nextTick(() => {
      const partnerCommentField = document.getElementById('partner-comment-field');
      partnerCommentField.focus();
      partnerCommentField.scrollTop = Number(partnerCommentField.scrollHeight);
    });
  },
  methods: {
    getButtonLabel (index) {
      if (this.miscButtonLabels[index]) return this.miscButtonLabels[index].label;
      return index;
    },
    async postComment () {
      const { currentProduct, currentTab, apps } = this.appStore;
      this.error = false;

      const { responseCode, data } = await this.updateComment(this.id, currentProduct, this.theModel);
      if (responseCode === 200) {
        this.appStore.updatePartnerComment(this.id, this.theModel);
        return this.toggleEdit(false);
      }

      this.error = true;
    },
    toggleEdit (show) {
      EventBus.$emit('toggle-partner-comment', { id: this.id, show });
    }
  }
}
</script>
