<template>
  <div id="chosen-offer-wrapper">
    <div v-if="rejectLoanPanel" :class="(panelFields.error.rejectReason) ? 'error-div' : ''">
      <div :class="(customerVisitToBranchError) ? 'error-div' : ''">
        <div v-if="isNotOnlineLender" id="ipa-offer-wrapper">
          <div> {{ getTexts('customerVisitToBranch') }} </div>
          <select v-model="customerVisitToBranchModel">
            <option v-for="option in yesNoOptions" :key="option.value" :value="option.value">
              {{ option.text }}
            </option>
          </select>
        </div>
        <div v-if="customerVisitToBranchError" id="error-text"> {{ getTexts('customerVisitToBranchError') }} </div>
      </div>
      <div :class="(rejectReasonError) ? 'error-div' : ''">
        <div id="ipa-offer-wrapper">
          <div v-if="locale === 'sg'">
            <div>
              <div>{{ getTexts('rejectCategory') }}</div>
              <select v-model="rejectCategoryModel">
                <option v-for="option in getDeclineOptions" :key="option.value" :value="option.value">
                  {{ option.text }}
                </option>
              </select>
            </div>
            <div>
              <div>{{ getTexts('rejectSubCategory') }}</div>
              <select v-model="rejectReasonModel" :disabled="hasRejectCategory">
                <option v-for="option in getDeclineSubCategory" :key="option.value" :value="option.value">
                  {{ option.text }}
                </option>
              </select>
            </div>
          </div>
          <div v-if="locale === 'hk'">
            <div>
              <div>{{ getTexts('rejectReason') }}</div>
              <select v-model="rejectReasonModel">
                <option v-for="option in getDeclineOptions" :key="option.value" :value="option.value">
                  {{ option.text }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div v-if="rejectReasonError" id="error-text">{{ getTexts('rejectReasonError') }}</div>
      </div>
      <div :class="(rejectReasonOtherError) ? 'error-div' : ''">
        <div style="margin-top: 10px;">
          <div class="label">{{ locale === 'sg' ? getTexts('remarks') : getTexts('otherRejectReason') }}</div>
          <textarea v-model="otherRejectReasonModel" />
          <div v-if="rejectReasonOtherError" id="error-text">{{ getTexts('rejectReasonError') }}</div>
        </div>
      </div>
      <div style="display: flex; align-items: center">
        <checkbox
          id="isBlacklisted"
          :var="blacklistData"
          :error-var="errorFields"
          :label="getTexts('blacklistCheckbox')"
          :default="false"
        />

        <infoTooltip style="margin-left:8px" :body-text="getTexts('blacklistTooltip')" />
      </div>
    </div>

    <checkbox
      v-if="app.uploads"
      id="documentsReviewed"
      :var="panelFields"
      :error-var="panelFields.error"
      :label="getTexts('docReviewed')"
      :default="false"
      class="bottom-spacer"
    />

    <div class="cta-buttons-flexbox">
      <button v-if="!rejectLoanPanel" class="sharp-corner-button green-button" :disabled="processingReq" @click="acknowledge(app._id)"> {{ getButtonLabel('acknowledgeBtn') }} </button>
      <button v-if="app.uploads" class="sharp-corner-button red-button" :disabled="processingReq" @click="reject(app._id)"> {{ getButtonLabel('loanRejectedBtn') }} </button>
      <button v-if="expanded" class="sharp-corner-button light-grey-button" @click="collapsePanel()"> {{ getButtonLabel('cancelBtn') }} </button>
    </div>
  </div>
</template>

<script>
import EventBus from '../js/eventBus';
import partnerPortalApi from '../mixins/apiServices/partnerPortal';
import displayOfferMixin from '../mixins/displayOfferMixin';
import offerPanelMixin from '../mixins/offerPanelMixin';
import checkbox from './inputFields/checkbox';
import infoTooltip from './infoTooltip.vue';

import { useDomainStore } from '../store/domain';

export default {
  components: {
    checkbox,
    infoTooltip,
  },
  mixins: [partnerPortalApi, displayOfferMixin, offerPanelMixin],
  props: {
    app: Object,
    gotFiles: Boolean,
    offer: Object,
  },
  data () {
    return {
      offerFunnel: 'pre-disburse',
      panelFields: {
        error: {},
      },
      expanded: false,
      rejectLoanPanel: false,
      panel: 'chosen',
      blacklistData: {
        isBlacklisted: false,
      },
      domainStore: useDomainStore(),
    }
  },
  computed: {
    miscTexts () {
      return this.domainStore.miscTexts();
    },
    miscButtonLabels () {
      return this.domainStore.miscButtonLabels();
    },
    
    hasRejectCategory () {
      // eslint-disable-next-line no-undef
      return !(this.rejectCategoryModel);
    },
  },
  mounted () {
    this.panelFields.documentsReviewed = this.getDocumentsReviewedValue();
    this.$watch('rejectReason', (newVal) => {
      if (newVal !== 'other') this.rejectReasonOtherError = false;
    });
  },
  methods: {
    collapsePanel () {
      this.panelFields = { error: {} }; // reset panel fields
      this.expanded = false;
      this.rejectLoanPanel = false;
    },
    validateRejectReason () {
      if (!this.rejectReason) {
        this.rejectReasonError = true;
        return false;
      }

      if ((this.rejectReason === 'other' || (this.rejectCategory === 'lender_policy' && this.rejectReason === 'others')) && (!(/\S/.test(this.rejectReasonOther)) || this.rejectReasonOther === undefined)) {
        this.rejectReasonOtherError = true;
        return false;
      }

      return true;
    },
    async acknowledge (appId) {
      if (this.gotFiles && !this.panelFields.documentsReviewed) {
        return this.panelFields.error.documentsReviewed = true;
      }

      this.processingReq = true;
      await this.acknowledgeApp(appId, this.currentProduct, true).then(({ responseCode, data }) => {
        if (responseCode === 200 && Object.keys(data)[0] === this.currentProduct) {
          EventBus.$emit('refreshApp', data);
          this.processingReq = false;
        } else if (responseCode === 409) {
          alert(`${this.getTexts('concurrencyHeader')} \n${this.getTexts('concurrencyError')}`);
          EventBus.$emit('refreshApp', data);
          this.processingReq = false;
        }
      });
    },
    reject () {
      if (!this.rejectLoanPanel) {
        if (this.gotFiles && !this.panelFields.documentsReviewed) {
          return this.panelFields.error.documentsReviewed = true;
        }

        this.rejectLoanPanel = !this.rejectLoanPanel;
        this.expanded = true;
        return;
      }
      if (!this.validateRejectReason()) return;

      const emitObj = {
        appId: this.app._id,
        product: this.currentProduct,
        otherReason: this.rejectReasonOther,
        customerVisitToBranch: this.customerVisitToBranch,
        isBlacklisted: this.blacklistData.isBlacklisted
      }

      if (this.locale === 'hk') {
        const d = this.rejectTexts.find(d => d.value === this.rejectReason);
        const reason = (this.rejectReasonOther) ? `${this.rejectReason} - ${this.rejectReasonOther}` : this.rejectReason;
        emitObj.reason = reason;
        emitObj.reasonTxt = d.value ? d.text : undefined;
        emitObj.internalReason = `After doc review: ${reason}`;
      }

      if (this.locale === 'sg') {
        const categoryCopy = this.rejectTexts.find(d => d.value === this.rejectCategory);
        const subCategoryCopy = this.rejectSubCategory.find(category => category.value === this.rejectReason);
        const texts = { categoryCopy, subCategoryCopy };
        const internalReason = (this.rejectReasonOther) ? `${this.rejectCategory} - ${this.rejectReason} - ${this.rejectReasonOther}` : `${this.rejectCategory} - ${this.rejectReason}`;

        const rejectReasonData = {
          category: this.rejectCategory,
          subCategory: this.rejectReason,
          remark: this.rejectReasonOther,
          isDocReviewed: this.offerObj.documentsReviewed || false,
        }

        emitObj.reason = rejectReasonData;
        emitObj.reasonTxt = texts;
        emitObj.internalRejectReason = internalReason;
      }

      EventBus.$emit('reject-app', emitObj);
    },
    getButtonLabel (index) {
      if (this.miscButtonLabels[index]) return this.miscButtonLabels[index].label;
      return index;
    },
    getTexts (index) {
      if (this.miscTexts[index]) return this.miscTexts[index].label;
      return index;
    },
    getDocumentsReviewedValue () {
      const { offer } = this.app;
      if (!Object.prototype.hasOwnProperty.call(offer, 'documentAcknowledgementStages')) return false;

      return offer.documentAcknowledgementStages.includes('chosen');
    },
  },
}
</script>

<style scoped>
button {
  margin: 0 !important;
}

table, div {
  font-size: 0.86rem;
}

.test {
  /* color: black; */
}

input {
  width: 100%;
}

table tr > td:first-child {
  width: 60%;
}

table tr > td:not(:first-child) {
  width: 40%;
}
</style>
