<template>
  <div class="partner-comment-textarea">
    <div class="appointment-input-container">
      <input id="partner-appointment-field" v-model="theDateModel" type="date" :min="today">
      <input v-model="theTimeModel" type="time" step="300">
    </div>
    <div v-if="error" class="error-message"> Save failed </div>
    <button class="sharp-corner-button" :class="(disableButton) ? 'disabled-button' : 'green-button'" :disabled="disableButton" @click="postAppointmentBooking()"> {{ (appointmentDateTime) ? getButtonLabel('editAppointment') : getButtonLabel('addAppointment') }} </button>
    <button v-if="appointmentDateTime" class="red-button sharp-corner-button" @click="postAppointmentBooking('removeAppointment')"> {{ getButtonLabel('cancelAppointment') }} </button>
    <button class="light-grey-button sharp-corner-button" @click="toggleEdit(false)"> {{ getButtonLabel('discardCommentBtn') }} </button>
  </div>
</template>

<script>
import moment from 'moment';
import EventBus from '../../js/eventBus';
import partnerPortalApi from '../../mixins/apiServices/partnerPortal';

import { useDomainStore } from '../../store/domain';

export default {
  mixins: [partnerPortalApi],
  props: {
    id: String,
    appointmentDateTime: String,
    appointmentAddress: String,
  },
  data () {
    return {
      dateModel: '',
      timeModel: '',
      momentDate: '',
      momentTime: '',
      error: false,
      errorMessage: '',
      appStore: useAppStore(),
      domainStore: useDomainStore(),
    }
  },
  computed: {
    miscButtonLabels () {
      return this.domainStore.miscButtonLabels();
    },
    addressesList () {
      return this.domainStore.addressesList();
    },
    currentProduct () {
      return this.appStore.currentProduct;
    },
    addressList () {
      const list = this.addressesList[this.currentProduct];
      return list;
    },
    today () {
      return new Date().toISOString().split('T')[0];
    },
    disableButton () {
      if (!this.theDateModel || !this.theTimeModel) return true;
      if (this.momentDate === this.theDateModel && this.momentTime === this.theTimeModel) return true;
      return false;
    },
    theDateModel: {
      get () {
        return this.dateModel;
      },
      set (val) {
        this.dateModel = val;
      }
    },
    theTimeModel: {
      get () {
        return this.timeModel;
      },
      set (val) {
        this.timeModel = val;
      }
    }
  },
  beforeMount () {
    if (this.appointmentDateTime) {
      this.momentDate = moment(this.appointmentDateTime).format('YYYY-MM-DD');
      this.momentTime = moment(this.appointmentDateTime).format('HH:mm');
      this.theDateModel = this.momentDate;
      this.theTimeModel = this.momentTime;
    };

    this.$nextTick(() => {
      const partnerAppointmentField = document.getElementById('partner-appointment-field');
      partnerAppointmentField.focus();
      partnerAppointmentField.scrollTop = Number(partnerAppointmentField.scrollHeight);
    });
  },
  methods: {
    getButtonLabel (index) {
      if (this.miscButtonLabels[index]) return this.miscButtonLabels[index].label;
      return index;
    },
    toggleEdit (show) {
      EventBus.$emit('toggle-appointment-booking', { id: this.id, show });
    },
    appendDateTime () {
      return `${this.theDateModel}T${this.theTimeModel}`;
    },
    async postAppointmentBooking (purpose = undefined) {
      const newDate = moment(this.appendDateTime());
      const todayDate = moment(new Date());
      if (moment(newDate).isBefore(todayDate)) {
        alert('Appointment date invalid');
        return;
      }
      const { currentTab, currentProduct, apps: allApps } = this.appStore;
      const apps = allApps[currentProduct][currentTab];
      const currentApp = apps.find(app => app._id === this.id);
      this.error = false;

      if (!purpose) purpose = this.appointmentDateTime ? 'editAppointment' : 'addAppointment';
      const postObject = {
        appId: this.id,
        appointmentDetails: { appointmentDateTime: newDate, appointmentAddress: this.appointmentAddress || this.addressList[0].value },
        product: currentProduct,
        purpose,
        portal: 'pp',
      };

      if (purpose === 'removeAppointment') {
        EventBus.$emit('cancel-appointment-modal', postObject);
        return;
      }

      const { data, responseCode } = await this.updateAppointment(postObject);
      if (responseCode === 200) {
        EventBus.$emit('fetchAndRefreshAppList');
        return;
      }

      if (responseCode !== 200) {
        const { error } = data;
        alert(error);
      }

      this.error = true;
    }
  }
}
</script>
