import apiBaseService from './apiBaseService'
import authMixin from './auth';

import { useAppStore } from '../../store/app';
import { useAuthStore } from '../../store/auth';

export default {
  mixins: [apiBaseService, authMixin],

  computed: {
    currentProduct () {
      return this.appStore.currentProduct;
    },
    token () {
      return this.authStore.token;
    },
    user () {
      return this.authStore.user;
    },
    partnerPortalToken () {
      return this.authStore.partnerPortalToken;
    },
  },

  data () {
    return {
      processingReq: false,
      appStore: useAppStore(),
      authStore: useAuthStore(),
    }
  },
  
  methods: {
    async giveIPA (appId, offerDetails, product, panel, subStage) {
      return await this.apiRequest({
        method: 'PUT',
        headers: { authorization: this.token },
        data: { appId, offerDetails, product, subStage },
        url: (panel === 'offer-revision') ? '/partner/revise-ipa' : '/partner/give-ipa',
      })
    },
    async ignoreReviseIPA (appId, product, subStage) {
      return await this.apiRequest({
        method: 'POST',
        headers: { authorization: this.token },
        data: { appId, product, subStage },
        url: '/partner/ignore-revise-ipa',
      });
    },
    async declineApp (appId, product, reason, panel, isBlacklisted) {
      return await this.apiRequest({
        method: 'POST',
        headers: { authorization: this.token },
        data: { appId, product, reason, isBlacklisted },
        url: (panel === 'offer-revision') ? '/partner/decline-given-ipa' : '/partner/decline-ipa'
      })
    },
    async acknowledgeApp (appId, product, documentsAcknowledged) {
      return await this.apiRequest({
        method: 'POST',
        headers: { authorization: this.token },
        data: { appId, product, documentsAcknowledged },
        url: '/partner/acknowledge-ipa'
      })
    },
    async disburseApp (appId, product, offerDetails) {
      return await this.apiRequest({
        method: 'POST',
        headers: { authorization: this.token },
        data: { appId, product, offerDetails },
        url: '/partner/disburse-ipa'
      })
    },
    async rejectApp (payload) {
      const {
        appId, product, reason, stage, subStage, customerVisitToBranch, isBlacklisted,
      } = payload;

      let partnerToken = await this.getPartnerApiToken();

      const postData = {
        product,
        reason,
        customerVisitToBranch,
        offerRevision: (stage === 'offerRevision'),
        isBlacklisted,
      };

      const response = await this.partnerApiRequest({
        method: 'POST',
        headers: { authorization: `Bearer ${partnerToken}` },
        url: `/applications/${appId}/reject-ipa`,
        data: postData,
      });

      if ([200, 409].includes(response.responseCode)) {
        return response;
      }

      partnerToken = await this.retryLoginToPartnerApi();

      return await this.partnerApiRequest({
        method: 'POST',
        headers: { authorization: `Bearer ${partnerToken}` },
        url: `/applications/${appId}/reject-ipa`,
        data: postData,
      });
    },
    async updateComment (appId, product, comment) {
      return await this.apiRequest({
        method: 'POST',
        headers: { authorization: this.token },
        data: { appId, product, comment },
        url: '/partner/updateComment'
      })
    },
    async updateAppointment (data) {
      return await this.apiRequest({
        method: 'POST',
        headers: { authorization: this.token },
        url: '/partner/update-appointment',
        data
      })
    },
    async updateAppointmentAddress (data) {
      return await this.apiRequest({
        method: 'POST',
        headers: { authorization: this.token },
        url: '/partner/update-appointment-address',
        data
      })
    },
    async updateAppointmentDocuments (data) {
      return await this.apiRequest({
        method: 'POST',
        headers: { authorization: this.token },
        url: '/partner/update-appointment-documents',
        data
      })
    },
    async updateUploadDocuments (data) {
      return await this.apiRequest({
        method: 'POST',
        headers: { authorization: this.token },
        url: '/partner/update-upload-documents',
        data
      })
    },
    async updateAppointmentMissed (data) {
      return await this.apiRequest({
        method: 'POST',
        headers: { authorization: this.token },
        url: '/partner/appointment-missed',
        data,
      });
    },
  }
}
