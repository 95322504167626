<template>
  <div v-if="modal" class="modal-container">
    <div class="modal-content">
      <img src="../assets/img/cross.svg" alt="close" class="close-button" @click="(status === 'success') ? refreshApp() : (modal = false)">
      <template v-if="status === 'pendingConfirmation'">
        <strong>{{ getText('confirmationText') }}</strong> {{ formatTime(postObject.appointment) }}
        <button :class="(pending) ? 'disabled-button' : 'yellow-button'" @click="confirmMissed()">{{ getText('confirmBtn') }}</button>
        <button class="light-grey-button" @click="modal = false">{{ getText('cancelBtn') }}</button>
      </template>

      <template v-else-if="status === 'conflict'">
        <h2>{{ statusMessage }}</h2>
        <button class="green-button" @click="refreshApp()"> Ok, refresh </button>
      </template>

      <template v-else-if="status === 'error'">
        <h1> Error! </h1>
        <h3> {{ statusMessage }} </h3>
        <button class="red-button" @click="modal = false"> Close </button>
      </template>
    </div>
  </div>
</template>

<script>
import EventBus from '../js/eventBus';
import moment from 'moment';
import partnerPortalApi from '../mixins/apiServices/partnerPortal';

import { useDomainStore } from '../store/domain';

export default {
  mixins: [partnerPortalApi],

  data () {
    return {
      modal: false,
      pending: false,
      postObject: {},
      newApps: {},
      status: 'pendingConfirmation',
      statusMessage: '',
      domainStore: useDomainStore(),
    }
  },

  computed: {
    appointmentMissedText () {
      return this.domainStore.appointmentMissedText();
    }
  },

  watch: {
    modal (newVal) {
      if (newVal === false) {
        this.status = 'pendingConfirmation';
        this.statusMessage = '';
      }
    }
  },

  mounted () {
    EventBus.$on('missed-appointment-modal', this.toggleMissedAppointmentModal);
  },

  unmounted () {
    EventBus.$off('missed-appointment-modal', this.toggleMissedAppointmentModal);
  },

  methods: {
    getText (key) {
      if (this.appointmentMissedText[key]) return this.appointmentMissedText[key].label;
      return key;
    },

    formatTime (time) {
      return moment(time).format('LLL');
    },

    async confirmMissed () {
      this.pending = true;
      const postObject = {
        appId: this.postObject.appId,
        product: this.postObject.product,
      };
      const { data, responseCode } = await this.updateAppointmentMissed(postObject);
      if ([200, 409].includes(responseCode) && Object.keys(data)[0] === this.postObject.product) {
        this.newApps = data;
        if (responseCode === 200) {
          this.pending = false;
          return this.refreshApp();
        } else if (responseCode === 409) {
          this.status = 'conflict';
          this.statusMessage = 'Conflict, app has already been updated - refresh list';
          this.pending = false;
          return;
        }
      }

      // Fallback: error
      this.status = 'error'
      this.statusMessage = data;
      this.pending = false;
    },

    refreshApp () {
      EventBus.$emit('refreshApp', this.newApps);
      this.modal = false;
    },
    
    toggleMissedAppointmentModal (payload) {
      this.modal = true;
      this.postObject = payload;
    }
  },
}
</script>
