<template>
  <div>
    <select v-model="selectedAddress" style="width: 100%">
      <option v-for="(address, index) in addressList" :key="`appointment-address-${index}`" :value="address.value">{{ address.text }}</option>
    </select>

    <button class="sharp-corner-button" :class="(disableButton) ? 'disabled-button' : 'green-button'" :disabled="disableButton" @click="postAppointmentAddress()"> {{ getButtonLabel('updateAppointmentAddress') }} </button>
    <button class="light-grey-button sharp-corner-button" @click="toggleEdit(false)"> {{ getButtonLabel('cancelBtn') }} </button>
  </div>
</template>

<script>
import EventBus from '../../js/eventBus';
import partnerPortalApi from '../../mixins/apiServices/partnerPortal';

import { useAppStore } from '../../store/app';
import { useDomainStore } from '../../store/domain';

export default {
  mixins: [partnerPortalApi],

  props: {
    id: String,
    appointmentAddress: String,
  },

  data () {
    return {
      selectedAddress: this.appointmentAddress,
      appStore: useAppStore(),
      domainStore: useDomainStore(),
    }
  },

  computed: {
    addressesList () {
      return this.domainStore.addressesList();
    },

    miscButtonLabels () {
      return this.domainStore.miscButtonLabels();
    },

    addressList () {
      const list = this.addressesList[this.currentProduct];
      return list;
    },

    currentProduct () {
      return this.appStore.currentProduct;
    },

    disableButton () {
      if (this.selectedAddress === this.appointmentAddress) return true;
      return false;
    }
  },

  mounted () {
    if (!this.selectedAddress) this.selectedAddress = this.addressList[0].value;
  },

  methods: {
    toggleEdit (show) {
      EventBus.$emit('toggle-appointment-address', { id: this.id, show });
    },

    async postAppointmentAddress () {
      this.error = false;
      const postObject = {
        appId: this.id,
        appointmentAddress: this.selectedAddress,
        product: this.currentProduct,
      };

      const { data, responseCode } = await this.updateAppointmentAddress(postObject);
      if (responseCode === 200) {
        this.appStore.updateAppointmentAddress(this.id, this.selectedAddress);
        return this.toggleEdit(false);
      }
    },

    getButtonLabel (index) {
      if (this.miscButtonLabels[index]) return this.miscButtonLabels[index].label;
      return index;
    }
  }
}
</script>
