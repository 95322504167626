<template>
  <div v-if="modal" class="modal-container">
    <div class="modal-content">
      <img src="../assets/img/cross.svg" alt="close" class="close-button" @click="(status === 'success') ? refreshApp() : (modal = false)">
      <h2 class="space-bottom">{{ getTexts('generalConfirmHeader') }}</h2>
      <p class="space-bottom">{{ getTexts('cancelAppointmentConfirmation') }}</p>
      <div v-if="error" class="error-message"> Save failed </div>
      <button class="red-button sharp-corner-button" @click="confirmCancel()">{{ getButtonLabel('cancelAppointment') }}</button>
      <button class="light-grey-button sharp-corner-button" @click="modal = false">{{ getButtonLabel('discardCommentBtn') }}</button>
    </div>
  </div>
</template>

<script>
import EventBus from '../js/eventBus';
import partnerPortalApi from '../mixins/apiServices/partnerPortal';

import { useDomainStore } from '../store/domain';

export default {
  mixins: [partnerPortalApi],

  data () {
    return {
      modal: false,
      error: false,
      postObject: {},
      domainStore: useDomainStore(),
    }
  },

  computed: {
    miscTexts () {
      return this.domainStore.miscTexts();
    },
    miscButtonLabels () {
      return this.domainStore.miscButtonLabels();
    },
  },

  mounted () {
    EventBus.$on('cancel-appointment-modal', this.cancelAppointmentModal)
  },
  unmounted () {
    EventBus.$off('cancel-appointment-modal', this.cancelAppointmentModal);
  },
  methods: {
    getTexts (index) {
      if (this.miscTexts[index]) return this.miscTexts[index].label;
      return index;
    },
    getButtonLabel (index) {
      if (this.miscButtonLabels[index]) return this.miscButtonLabels[index].label;
      return index;
    },
    async confirmCancel () {
      this.error = false;
      const { data, responseCode } = await this.updateAppointment(this.postObject);
      if (responseCode === 200) {
        this.modal = false;
        EventBus.$emit('fetchAndRefreshAppList');
      }

      this.error = true;
    },

    cancelAppointmentModal (payload) {
      this.modal = true;
      this.postObject = payload;
    }
  }
}
</script>
