<template>
  <div id="tableWrapper" class="table-wrapper">
    <table v-if="apps.length > 0" class="app-table display-only">
      <thead>
        <tr>
          <th class="table-header" :data-content="tableHeaderLabel(UserViewMixin_appIdentifier)" />
          <th v-for="(field, index) in fields" :id="field" :key="index">
            <div> {{ tableHeaderLabel(index) }} </div>
          </th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(app, appIndex) in apps" :key="app._id">
          <th :data-content="`${appIndex + 1}. ${app[UserViewMixin_appIdentifier] || ''}`" style="cursor: pointer;" @dblclick="copyFieldMixin_copy(`${ appIndex + 1 }. ${app[UserViewMixin_appIdentifier] || ''}`)" />
          <td v-for="(field, index) in fields" :key="index">
            <partner-comment
              v-if="index === 'partnerComment'"
              :id="app._id"
              :comment="app.partnerComment"
              :field-toggle="partnerCommentStatus[app._id]"
            />
            <field-display v-else :id="app._id" :field-name="index" :field-val="getFieldValues(index, app[index], app)" :tab="tab" />
          </td>
        </tr>
      </tbody>
    </table>

    <div v-else class="cleared-section-wrapper">
      <cleared-table />
    </div>
  </div>
</template>

<script>
import tableMixin from '../mixins/cssMixin/tableMixin';
import copyFieldMixin from '../mixins/copyFieldMixin';
import fieldDisplay from './fieldDisplay';

import { useDomainStore } from '../store/domain';

export default {
  components: {
    'field-display': fieldDisplay,
  },

  mixins: [tableMixin, copyFieldMixin],

  props: {
    apps: Array,
    fields: Object,
    tab: String,
  },

  data () {
    return {
      domainStore: useDomainStore(),
    }
  },

  computed: {
    rejectionReasons () {
      return this.domainStore.rejectionReasons();
    },
    rejectionCategories () {
      return this.domainStore.rejectionCategories();
    },
    rejectionSubCategories () {
      return this.domainStore.rejectionSubCategories();
    },
  },

  methods: {
    getFieldValues (index, appIndex, app) {
      if (index !== 'declineReason') {
        return appIndex;
      }

      if (appIndex && typeof appIndex === 'string' && index === 'declineReason') {
        const [docReviewKey, ...reasonParts] = appIndex.split(': ');
        const joinReason = reasonParts.join(': ');
        const [reasonKey, ...reasonDescription] = docReviewKey === 'After doc review' ? joinReason.split(' - ') : appIndex.split(' - ');
        const fullReason = reasonDescription.join(' - ');
        const rejectionReasonLabel = this.rejectionReasons[reasonKey].label;
        const prefix = docReviewKey === 'After doc review' ? `${docReviewKey}: ` : '';
        const declineReason = (fullReason) ? `${prefix}${rejectionReasonLabel} - ${fullReason}` : `${prefix}${rejectionReasonLabel}`;
        return declineReason;
      }

      if ((!appIndex || typeof appIndex === 'object') && index === 'declineReason') {
        const { offerDeclinedReason, offerRejectReason } = app;
        if (!appIndex && (!offerDeclinedReason && !offerRejectReason)) return '';

        const reason = offerRejectReason || offerDeclinedReason || appIndex;

        if (typeof reason === 'object') {
          const { category, remark, subCategory, isDocReviewed } = reason;
          const rejectionCategoryLabel = this.rejectionCategories[category].label;
          const rejectionSubCategoryLabel = this.rejectionSubCategories[subCategory].label;
          const declineReason = (isDocReviewed) ? `After doc review: ${rejectionCategoryLabel}: ${rejectionSubCategoryLabel}` : `${rejectionCategoryLabel}: ${rejectionSubCategoryLabel}`;
          const fullDeclineReason = (remark) ? `${declineReason} - ${remark}` : declineReason;
          return fullDeclineReason;
        } else if (typeof reason === 'string') {
          const [docReviewKey, ...reasonParts] = reason.split(': ');
          const joinReason = reasonParts.join(': ');
          /** @description band aid for the issue caused by offerDeclined reason not being object due to lack of lender refresh */
          const [reasonKey, ...reasonDescription] = docReviewKey === 'After doc review' ? joinReason.split(' - ') : reason.split(' - ');
          const fullReason = reasonDescription.join(' - ');
          const rejectionReasonLabel = this.rejectionReasons[reasonKey].label;
          const prefix = docReviewKey === 'After doc review' ? `${docReviewKey}: ` : '';
          const declineReason = (fullReason) ? `${prefix}${rejectionReasonLabel} - ${fullReason}` : `${prefix}${rejectionReasonLabel}`;
          return declineReason;
        }
      }

      if (!appIndex) return '';
      return appIndex;
    }
  }
}
</script>
